import React from "react";
import { Container, useMediaQuery, useTheme } from "@material-ui/core";
import Snackbar from "@material-ui/core/Snackbar";
import { makeStyles, withStyles } from "@material-ui/core";
import { connect } from "react-redux";
import { currentPageAction } from "../../redux/actions/currentPageAction";
import { pageTrackingAction } from "../../redux/actions/pageTrackingAction";
import findNextPageOf from "../../utils/findNextPageOf";
import FileUpload from "../common/FileUpload";
import MessageInfo from "../../assets/svgs/MessageInfo";
import Footer from "../Footer";
import { getCorpus, saveImages } from "../../network/services";
import LinearProgress from "@material-ui/core/LinearProgress";
// import ekush from "../../assets/ekush.svg";
import robotReading from "../../assets/robotReading.svg";
import { completedLevelAction } from "../../redux/actions/compeltedLevelAction";
import textDisplayContent from "./content";

const levelScores = ["২৫", "৭৫", "২২৫", "৫৭৫", "৯৯৯"];

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 10,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor:
      theme.palette.grey[theme.palette.type === "light" ? 200 : 700],
  },
  bar: {
    borderRadius: 5,
    backgroundColor: "rgba(205, 38, 51, 1)",
  },
}))(LinearProgress);
const useStyles = makeStyles((theme) => ({
  textDisplayContainer: {
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down("sm")]: {
      padding: 0,
    },
  },
  topSection: {
    display: "flex",
    flexDirection: "column",
    marginBottom: theme.spacing(2),
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      marginTop: theme.spacing(0),
      padding: theme.spacing(2),
      paddingTop: 0,
    },
  },
  guide: {
    background: "white",
    textAlign: "center",
    width: "fit-content",
    marginTop: theme.spacing(2),
    padding: 10,
    paddingLeft: 15,
    paddingRight: 15,
    borderRadius: 32,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginTop: 0,
      borderRadius: 0,
    },
    "& p": {
      fontSize: 14,
      fontWeight: 500,
      margin: 0,
      marginLeft: 5,
      marginTop: 4,
      color: "rgba(36, 37, 38, 1)",
      [theme.breakpoints.down("sm")]: {
        marginLeft: 0,
        fontSize: 14,
      },
    },
  },
  top_section1: {
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  imageContainer: {
    display: "flex",
    padding: 10,
    justifyContent: "center",
    "& img": {
      borderRadius: "50%",
      filter: "contrast(1.2)",
      height: 226,
      width: 222,
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: -30,
      "& img": {
        height: 180,
        width: 180,
      },
    },
  },
  topSectionContent: {
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0,
    },
    "& h2": {
      marginTop: 0,
    },
    "& p": {
      fontSize: 20,
    },
  },
  returnSection: {
    padding: "8px 15px 8px 15px",
    background: "white",
    borderRadius: 110,
    fontWeight: 600,
    width: "fit-content",
    cursor: "none",
    textAlign: "center",
    border: "1px solid #95a5a78a",
    [theme.breakpoints.down("sm")]: {
      fontSize: 15,
    },
    //color: "rgba(54, 43, 115, 1)",
  },
  ekush_placeholder: {
    marginLeft: 3,
    marginRight: 3,
    fontWeight: 400,
    fontSize: 20,
    [theme.breakpoints.down("sm")]: {
      fontSize: 17,
      fontWeight: 600,
    },
  },
  bottomSection: {
    textAlign: "center",
    background: "rgba(255, 255, 255, .7)",
    borderRadius: "80px",
    padding: theme.spacing(2),
    backdropFilter: "blur(24px)",
    marginBottom: 40,
    marginTop: 40,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",

    [theme.breakpoints.down("sm")]: {
      marginTop: 0,
      padding: 0,
      borderRadius: 0,
    },
  },
  bottom_section1_during: {
    fontSize: 24,
    fontWeight: 600,
  },
  bottom_section1: {
    fontSize: 24,
    fontWeight: 600,
    width: "60%",
    margin: "auto",
  },
  bottom_section2: {
    fontSize: 20,
    fontWeight: 400,
    width: "60%",
    margin: "auto",
    marginTop: theme.spacing(1),
  },
  inputField: {
    width: "60%",
    margin: "auto",
    marginTop: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      paddingBottom: 20,
    },
  },
  section_2: {
    fontWeight: 500,
  },
  returnSectionContainerForMobile: {
    display: "flex",
    justifyContent: "center",
  },
  scoreContainer: {
    background: "rgba(242, 242, 242, 1)",
    borderRadius: 8,
    width: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    zIndex: 40,
    top: -25,
    "& p": {
      marginTop: 0,
      paddingTop: 10,
      paddingBottom: 10,
    },
    [theme.breakpoints.down("sm")]: {
      position: "relative",
      top: 0,
      width: "100%",
      background: "white",
      borderRadius: 0,
      "& p": {
        fontSize: 12,
      },
    },
  },
  topSectionDuring: {
    textAlign: "center",
    background: "rgba(255, 255, 255, 0.32)",
    borderRadius: "80px",
    paddingBottom: theme.spacing(2),
    backdropFilter: "blur(24px)",
    margin: theme.spacing(2),
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      borderRadius: "0",
      margin: theme.spacing(0),
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      padding: theme.spacing(2),
      paddingTop: 0,
    },
  },
  progressContainer: {
    width: "40%",
    margin: "auto",
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  snackbar: {
    "& .MuiSnackbar-root": {
      "& .MuiSnackbarContent-root": {
        backgroundColor: "whitesmoke",
        fontFamily: "Noto Sans Bengali",
      },
    },
  },
  imageContainerDuring: {
    display: "flex",
    padding: 10,
    justifyContent: "center",

    "& img": {
      filter: "contrast(1)",
      height: 120,
      width: 120,
    },
    [theme.breakpoints.down("sm")]: {
      "& img": {
        height: 120,
        width: 120,
      },
    },
  },
}));

function shortenText(str) {
  const maxLength = 100;
  var trimmedString = str.substr(0, maxLength);
  trimmedString = trimmedString.substr(
    0,
    Math.min(trimmedString.length, trimmedString.lastIndexOf(" "))
  );
  return trimmedString;
}

function During() {
  const classes = useStyles();
  return (
    <>
      <div className={classes.topSectionDuring}>
        <div className={classes.imageContainerDuring}>
          <img src={robotReading} alt="robothellogit" />
        </div>
        <p className={classes.during}>অপেক্ষা করুন...</p>
        <div className={classes.progressContainer}>
          <BorderLinearProgress />
        </div>
      </div>
    </>
  );
}

function TextDisplay({
  setPageTracking,
  setCurrentPage,
  pageTrackerData,
  completedLevel,
  setCompletedLevel,
}) {
  const classes = useStyles();
  const theme = useTheme();
  const match = useMediaQuery(theme.breakpoints.down("sm"));
  const [textData, setTextData] = React.useState(null);
  const [isActive, setActiveFlag] = React.useState(false);
  const [textDataId, setTextDataId] = React.useState(null);
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);

  const getTextDetails = React.useCallback(async () => {
    const body = { uid: pageTrackerData.landing.data.userId };
    const result = await getCorpus(body);

    if (!result?.data) {
      getTextDetails();
    } else {
      setTextData(result.data);
      setTextDataId(result.data.id);
      setActiveFlag(true);
    }
  }, [pageTrackerData.landing.data.userId]);

  React.useEffect(() => {
    getTextDetails();
  }, [getTextDetails]);

  const onChangePage = (fileList) => {
    setActiveFlag(false);
    setTextData(null);
    saveImages(fileList, pageTrackerData.landing.data.userId, textDataId)
      .then(() => {
        const newPageTrackerData = {
          ...pageTrackerData,
          textDisplay: {
            ...pageTrackerData["textDisplay"],
            completed: true,
          },
        };
        //        setSnackbarOpen(true);
        setPageTracking(newPageTrackerData);
        setCompletedLevel();

        setCurrentPage(findNextPageOf("textDisplay"));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <Container maxWidth="md" className={classes.textDisplayContainer}>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        message="পুনরায় চেষ্টা করুন"
        className={classes.snackbar}
      />
      <div className={classes.topSection}>
        <div className={classes.guide}>
          {!match && <MessageInfo />}
          <p>
            {" "}
            নিচের লেখাটি একটি{" "}
            <span style={{ fontWeight: 700 }}>সাদা কাগজে নিজের হাতে</span>{" "}
            লিখুন। {textDisplayContent.guide}
          </p>
        </div>
        {textData && (
          <div className={classes.topSectionContent}>
            <p className={classes.top_section1}>{shortenText(textData.text)}</p>
          </div>
        )}
      </div>
      {!textData && <During />}
      {textData && (
        <Container maxWidth="md" className={classes.bottomSection}>
          <div className={classes.scoreContainer}>
            <p>
              এই ধাপটি সম্পন্ন করলে আপনি{" "}
              <b>{levelScores[completedLevel]} পয়েন্ট</b> পাবেন
            </p>
          </div>
          <div className={classes.inputField}>
            <FileUpload onSubmit={onChangePage} isActive={isActive} />
          </div>
        </Container>
      )}
      <Footer />
    </Container>
  );
}

const mapStateToProps = (state) => {
  return {
    pageTrackerData: state.pageTracking.pageTracker,
    completedLevel: state.completedLevel.completed,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setPageTracking: (data) => dispatch(pageTrackingAction(data)),
    setCurrentPage: (data) => dispatch(currentPageAction(data)),
    setCompletedLevel: () => dispatch(completedLevelAction()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TextDisplay);
