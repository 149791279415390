const ImageIcon = () => {
  return (
    <svg
      width="28"
      height="22"
      viewBox="0 0 28 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23.3332 0.333328H4.6665C3.60564 0.333328 2.58822 0.754756 1.83808 1.5049C1.08793 2.25505 0.666504 3.27246 0.666504 4.33333V17.6667C0.666504 18.7275 1.08793 19.7449 1.83808 20.4951C2.58822 21.2452 3.60564 21.6667 4.6665 21.6667H23.3332C24.394 21.6667 25.4115 21.2452 26.1616 20.4951C26.9117 19.7449 27.3332 18.7275 27.3332 17.6667V4.33333C27.3332 3.27246 26.9117 2.25505 26.1616 1.5049C25.4115 0.754756 24.394 0.333328 23.3332 0.333328ZM4.6665 19C4.31288 19 3.97374 18.8595 3.7237 18.6095C3.47365 18.3594 3.33317 18.0203 3.33317 17.6667V14.44L7.73317 10.0533C7.98241 9.80902 8.3175 9.67218 8.6665 9.67218C9.01551 9.67218 9.3506 9.80902 9.59984 10.0533L18.5465 19H4.6665ZM24.6665 17.6667C24.6665 18.0203 24.526 18.3594 24.276 18.6095C24.0259 18.8595 23.6868 19 23.3332 19H22.3065L17.2265 13.8933L18.3998 12.72C18.6491 12.4757 18.9842 12.3389 19.3332 12.3389C19.6822 12.3389 20.0173 12.4757 20.2665 12.72L24.6665 17.1067V17.6667ZM24.6665 13.3467L22.1598 10.8533C21.3999 10.1232 20.387 9.7155 19.3332 9.7155C18.2794 9.7155 17.2664 10.1232 16.5065 10.8533L15.3332 12.0267L11.4932 8.18666C10.7332 7.45657 9.72031 7.04883 8.6665 7.04883C7.6127 7.04883 6.59976 7.45657 5.83984 8.18666L3.33317 10.68V4.33333C3.33317 3.97971 3.47365 3.64057 3.7237 3.39052C3.97374 3.14047 4.31288 2.99999 4.6665 2.99999H23.3332C23.6868 2.99999 24.0259 3.14047 24.276 3.39052C24.526 3.64057 24.6665 3.97971 24.6665 4.33333V13.3467Z"
        fill="#1C1E21"
      />
    </svg>
  );
};

export default ImageIcon;
