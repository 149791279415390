const uploadContent = {
  bottom_section1: "আপনি জানেন কি?",
  bottom_section2:
    "ইউনেস্কো ২০০০ সাল থেকে বিশ্বব্যাপী আন্তর্জাতিক মাতৃভাষা দিবস উদযাপন শুরু করে।",
  after_upload_bottom1:
    "অসাধারণ, Saeef! আপনার হাতের লেখা জমা দেওয়ার জন্য অসংখ্য ধন্যবাদ।",
  after_upload_bottom2:
    "আপনি জানেন কি? একটি সমীক্ষায় বলা হয়েছে, বাংলা ভাষায় এক লক্ষেরও বেশি স্বতন্ত্র শব্দ রয়েছে। কিন্তু আমি তার মধ্যে খুব কম শব্দ জানি।",
  after_upload_bottom3: "আপনি কি আমাকে আরও বাংলা শব্দ শিখতে সাহায্য করতে চান?",
  fact_list: [
    <>
      ১৯৯৯ সালের ১৭ নভেম্বর অনুষ্ঠিত ইউনেস্কোর প্যারিস অধিবেশনে একটি প্রস্তাব
      উত্থাপন করা হয় ও এতে ১৮৮টি দেশ সমর্থন জানালে একুশে ফেব্রুয়ারিকে
      আন্তর্জাতিক মাতৃভাষা দিবস হিসেবে ঘোষণা করা হয় এবং ২০০০ সালের ২১
      ফেব্রুয়ারি থেকে দিবসটি জাতিসংঘের সদস্যদেশসমূহে যথাযথ মর্যাদায় পালিত
      হচ্ছে।
    </>,
    <>
      বাংলা ভারতের পশ্চিমবঙ্গ, ত্রিপুরা এবং আসাম রাজ্যের বরাক উপত্যকা অঞ্চলের
      সরকারী ভাষা। এমনকি ভারতের ২২ টি তফসিলি ভাষার মধ্যে বাংলা দ্বিতীয় সর্বাধিক
      জনপ্রিয়। শুধু তাই নয়, বিশ্বকবি রবীন্দ্রনাথ ঠাকুর ভারতীয় জাতীয় সঙ্গীত
      প্রথমে বাংলায় লেখেন।
    </>,
    <>
      পশ্চিম আফ্রিকার দেশ সিয়েরা লিওনে বাংলা একটি সরকারি ভাষা। সিয়েরা লিওন
      গৃহযুদ্ধে বাংলাদেশী শান্তিরক্ষা বাহিনী উল্লেখযোগ্য অবদান রাখে, যার
      স্বীকৃতি দেওয়ার জন্য বাংলাদেশী সৈন্যদের সম্মানে ২০০২ সালে বাংলাকে সিয়েরা
      লিওনের একটি সম্মানসূচক রাষ্ট্রীয় ভাষা হিসেবে নামকরণ করা হয়।
    </>,
    <>
      ১৯৫২ সালের ২১শে ফেব্রুয়ারির ঐতিহাসিক ঘটনার পর ‘একুশ’ নিয়ে গান রচিত করেন
      আবদুল গাফফার চৌধুরী, সেই গানটি হল আমাদের সকলের প্রিয়-"আমার ভাইয়ের রক্তে
      রাঙানো একুশে ফেব্রুয়ারি, আমি কি ভুলিতে পারি।" এই গানটিতে প্রথমে সুরারোপ
      করেন আব্দুল লতিফ,যা ১৯৫৪ সালে আলতাফ মাহমুদ আবার নতুন করে সুরারোপ করেন।
      সেখান থেকেই এই গানটি হয়ে গেল একুশের প্রভাতফেরীর গান।
    </>,
    <>
      ১৯৫২ সালের ভাষা শহীদদের আত্মত্যাগ এবং দীর্ঘদিনের বাংলা ভাষা আন্দোলনের
      ফলস্বরূপ, ১৯৫৬ সালের ২৯ ফেব্রুয়ারি পাকিস্তানের প্রথম সংবিধান প্রণীত
      হওয়ার সময় ২১৪(১) অনুচ্ছেদে উর্দুর সাথে বাংলাকে পাকিস্তানের রাষ্ট্রভাষা
      হিসেবে গৃহীত হয়। সংবিধানের ২১৪(১) অধ্যায়ে রাষ্ট্রভাষা সম্পর্কে লেখা হয়:
      <br />
      214.(1) The state language of Pakistan shall be Urdu and Bengali.
      <br /> [২১৪. (১) উর্দু এবং বাংলা হবে পাকিস্তানের রাষ্ট্রভাষা।]
    </>,
    <>
      মাত্র ১৬ বছর বয়সে রবীন্দ্রনাথ ঠাকুর ছোট গল্প লিখতে শুরু করেন এবং তাঁর
      প্রথম ছোট গল্প ছিল 'ভিখারিনী'। রবীন্দ্রনাথ ঠাকুরের বিপুল সাহিত্যসম্ভারের
      যেসব হিসাব পাওয়া যায় সে অনুযায়ী তিনি লিখেছিলেন ৫২টি কাব্যগ্রন্থ, ৩৮টি
      নাটক, ১৩টি উপন্যাস, ৩৬টি প্রবন্ধ এবং অন্যান্য আরও গদ্য, ৯৫টি ছোটগল্প এবং
      দুহাজারের ওপর গান।
    </>,
    <>
      বিশ্বকবি রবীন্দ্রনাথ ঠাকুর প্রথম অ-ইউরোপীয় যিনি সাহিত্যে নোবেল পুরস্কার
      পেয়েছেন । তাঁর কাব্যগ্রন্থ 'গীতাঞ্জলি'র ইংরেজি অনুবাদের জন্য সুইডিশ
      অ্যাকাডেমি তাঁকে নোবেল পুরস্কার দিয়েছিল।
    </>,
    <>
      বাংলা ভাষা ও সাহিত্যের আদি নিদর্শন "চর্যাপদ"। ১৯০৭ খ্রিস্টাব্দে
      মহামহোপাধ্যায় হরপ্রসাদ শাস্ত্রী, নেপালের রাজদরবারের গ্রন্থশালা থেকে
      চর্যার একটি খণ্ডিত পুঁথি উদ্ধার করেন।
    </>,
    <>
      চর্যাপদ শুধু প্রাচীন বাংলা সাহিত্যেরই নিদর্শন নয়, প্রাচীন বাংলা গানেরও
      নিদর্শন। প্রতিটি পদের শুরুতে রাগ-তাল ও প্রতি জোড়-পদে ‘ধ্রুব’ শব্দের
      উল্লেখ থাকায় নিশ্চিত প্রমাণিত হয় যে, এগুলি তখন গাওয়া হতো।
    </>,
    <>
      বাংলা ভাষার প্রথম সার্থক উপন্যাস বঙ্কিমচন্দ্র চট্টোপাধ্যায় রচিত
      "দুর্গেশনন্দিনী" (১৮৬৫)। তবে বাংলা ভাষার প্রথম উপন্যাস হচ্ছে প্যারীচাদ
      মিত্রের "আলালের ঘরের দুলাল" (১৮৫৮)।
    </>,
    <>
      বাংলা সাহিত্যের প্রথম নাটক তারাচরণ শিকদার রচিত ‘ভদ্রার্জুন’ (১৮৫২)। তবে
      বাংলা সাহিত্যের প্রথম সার্থক / আধুনিক নাটক মাইকেল মধুসূদন দত্তের
      ‘শর্মিষ্ঠা'(১৮৫৮)।
    </>,
    <>
      বাংলা ভাষায় প্রথম সনেট কৃতিত্ব মাইকেল মধুসূদন দত্তের "চতুর্দশপদী
      কবিতাবলী"। ১৪ অক্ষর সমন্বিত, ১৪ পংক্তিতে সমাপ্ত, অষ্টক ও ষষ্টকের পর্ব
      ভাগের নিয়মকে ধারণ করে পংক্তিসমূহের মেলবন্ধনের রীতিকে রক্ষা করে মধুসূদন
      দত্ত সনেটকে বাংলা ভাষায় জনপ্রিয় করে তোলেন।
    </>,
    <>
      রবীন্দ্রনাথ তাঁর "রুদ্রচন্ড" নাটকটি তাঁর বড় ভাই জ্যোতিরিন্দ্রনাথ ঠাকুরকে
      উৎসর্গ করেন। রবীন্দ্রনাথ ঠাকুরের প্রথম নাটক রুদ্রচন্ড (১৮৮১) নাটকটি ১৪টি
      দৃশ্যে বিন্যস্ত।
    </>,
    <>
      ‘করুণা’ ১৮৭৭-১৮৭৮ (১৯৬১) এটি রবীন্দ্রনাথের লেখা প্রথম উপন্যাস। তবে এটি
      তাঁর জীবদ্দশায় গ্রন্থাকারে প্রকাশিত হয় নি। এটি 'ভারতী’ পত্রিকায়
      ১৮৭৭-১৮৭৮ খ্রীস্টাব্দ পর্যন্ত ধারাবাহিকভাবে প্রকাশিত হয়। ১৯৬১ সালে
      পশ্চিমবঙ্গ সরকার কর্তৃক প্রকাশিত রবীন্দ্র রচনাবলীতে ‘করুণা’ প্রথম প্রকাশিত
      হয়। এটির পরিশেষ হয় নি বলে এটি উপন্যাসের সম্পূর্ণ মর্যাদা পায় নি
      (অসমাপ্ত উপন্যাস)। এটিকে বাদ দিয়ে রবীন্দ্রনাথ মোট তেরোটি উপন্যাস লিখেছেন।
    </>,
    <>
      ‘বৌ-ঠাকুরাণীর হাট’ ১৮৮৩ রবীন্দ্রনাথের প্রথম গ্রন্থাকারে প্রকাশিত উপন্যাস।
      যশোরের রাজা প্রতাপাদিত্য ও বাকলার জমিদার রামচন্দ্রের বিবাদকে উপজীব্য করে
      রচিত ঐতিহাসিক উপন্যাস। ১৩১৬ বঙ্গাব্দে বৌ-ঠাকুরাণীর হাট অবলম্বনে রচিত হয়
      রবীন্দ্রনাথের প্রায়শ্চিত্ত নাটকটি। প্রায়শ্চিত্ত ১৩৩৬ বঙ্গাব্দে
      পুনর্লিখিত হয়ে পরিত্রাণ নামে মুদ্রিত হয়।
    </>,
    <>
      শ্রীকৃষ্ণকীর্তন হল বড়ু চণ্ডীদাস রচিত মধ্যযুগের অন্যতম বাংলা কাব্য। এটি
      চর্যাপদের পর আদি-মধ্যযুগীয় বাংলা ভাষার আবিষ্কৃত দ্বিতীয় প্রাচীনতম
      নিদর্শন। ১৯০৯ খ্রিস্টাব্দে বসন্তরঞ্জন রায় বিদ্বদ্বল্লভ বাঁকুড়া জেলার
      কাঁকিল্যা গ্রামের একটি গোয়ালঘর থেকে এই কাব্যের খণ্ডিত পুথিটি আবিষ্কার
      করেন।
    </>,
    <>
      ‘কবর’ নাটক ১৯৫২ সালের ভাষা আন্দোলনের পটভূমিকায় রচিত। ১৯৫৩ সালের ১৭
      জানুয়ারি নাটকটি রচিত হয় এবং সর্বপ্রথম ১৯৫৩ সালের ২১শে ফেব্রুয়ারি জেলখানাতে
      মঞ্চস্থ হয়।
    </>,
  ],
};

export default uploadContent;
