const content = {
  aboutEkush: [
    "প্রিয় ডিজিটাল ভাষা শিল্পী,",

    "বাংলাদেশে তৈরি প্রজেক্ট একুশে আপনাকে স্বাগতম 😊",

    "এটি বাংলা ভাষার একটি উন্মুক্ত প্রজেক্ট (ওপেন সোর্স)। কিন্তু, প্রজেক্ট একুশের লক্ষ্য কি? প্রজেক্ট একুশের মাধ্যমে যে প্রযুক্তি তৈরি হবে তা কিভাবে ব্যবহৃত হবে?",

    "আমাদের জানা মতে আমাদের প্রিয় বাংলা হাতের লেখা পড়ার মতো শক্তিশালী প্রযুক্তি নেই। বাংলা কেন্দ্রিক প্রযুক্তি তৈরিতে পিছিয়ে থাকার এটিও একটি কারণ। এই সমস্যাটি সমাধানের একটি প্রয়াস “প্রজেক্ট একুশ”।",

    "প্রজেক্ট একুশের মাধ্যমে আমরা একটি শক্তিশালী কৃত্রিম বুদ্ধিমত্তা (এআই) তৈরি করতে চাই যা বাংলা হাতের লেখা চিনতে ও পড়তে পারবে।",

    "আমরা ইতোমধ্যে বাংলা হাতের লেখায় বিশ লক্ষ দোকানের ফর্দ (মেমো) এবং বিশ হাজার মাধ্যমিক শিক্ষার্থীর পরীক্ষার খাতা থেকে কম্পিউটারকে বাংলা শেখানোর জন্য তথ্য প্রস্তুত (ডেটা এনোটেট) করেছি।",

    "এখন উইকিপিডিয়া থেকে লেখা নিয়ে একটি বাংলা সংকলন (করপাস) ডিজাইন করেছি, এবং সেই লিপির হাতের লেখার নমুনা সংগ্রহ করছি। হাতের লেখা যারা জমা দিবেন, তাদের সকলের নাম ডেটাসেটে বাংলায় কৃত্রিম বুদ্ধিমত্তা (এআই) তৈরিতে ইতিহাস হয়ে থাকবে।",

    "সব মিলিয়ে একটি উন্মুক্ত অ্যাপ তৈরি করা হবে। একইসাথে এই পুরো ডেটাসেটটি সবার জন্য কোন শর্ত ছাড়াই প্রকাশ করা হবে যেন যে কেউ এটা দিয়ে বাংলায় আরও নতুন নতুন অ্যাপ / কৃত্তিম বুদ্ধিমত্তা তৈরি করতে পারবেন।",

    "আমাদের সবার প্রচেষ্টায় তৈরি একুশ দৃষ্টি প্রতিবন্ধীদের বাংলা লেখা পড়তে সাহায্য করবে, বিভিন্ন দাপ্তরিক / দালিলিক কাগজপত্র মুহূর্তে ডিজিটাইজ করবে, এমনকি যারা বাংলা পড়তে জানেন না তাদেরকেও বাংলা পড়তে এবং বাংলা নির্ভর সেবা নিতে সাহায্য করবে।",

    "কিন্তু তার জন্য প্রথমে আমাদের যা দরকার তা হলো অনেক অনেক হাতের লেখা। যাতে করে এই এআই মডেল বাংলা হাতের লেখা পড়া শিখতে পারে। আপনার হাতের লেখার মাধ্যমে বাংলা ভাষাকে এগিয়ে নিয়ে যাওয়ার জন্য আপনাকে অসংখ্য ধন্যবাদ 💖",

    "ইতি,",
  ],
  aboutIM: [
    "ইন্টেলিজেন্ট মেশিন্স বাংলাদেশের একটি প্রযুক্তি স্টার্টআপ। ২০১৮ সাল থেকে আমরা মূলত কৃত্রিম বুদ্ধিমত্তা (এআই) এবং অ্যাডভান্সড অ্যানালিটিক্স প্রযুক্তি নিয়ে কাজ করছি।",
    "বিশেষভাবে, আমরা আমাদের দেশের ছেলেমেয়েদের এই বিষয়গুলোতে দক্ষতা তৈরির জন্য চেষ্টা চালিয়ে যাচ্ছি।",

    "এখন আমাদের ঘরে-তৈরি সতেরোজন ডীপ লার্নিং ইঞ্জিনিয়ার, আটজন তথ্যবিজ্ঞানী (ডেটা সাইন্টিস্ট), এগারোজন কম্পিউটার প্রকৌশলী, তিনশজন অভিজ্ঞ তথ্য প্রস্তুতকারক (ডেটা এনোটেটর) এবং আরও অন্যান্য বিশেষ ক্ষেত্রে পারদর্শী সদস্য রয়েছে।",
  ],

  aboutTerms: [
    "আমাদের জানা মতে, বর্তমানে বাংলা হাতের লেখা পড়ার মতো তেমন কোন শক্তিশালী প্রযুক্তি নেই। যার ফলে বাংলা ভাষায় প্রযুক্তি তৈরির ক্ষেত্রে আমরা অনেকাংশেই পিছিয়ে থাকছি। তাই বাংলা কেন্দ্রিক প্রযুক্তিকে এগিয়ে নেয়ার জন্যই আমাদের এই প্রয়াস “প্রজেক্ট একুশ”।",
    "এই প্রজেক্টের মাধ্যমে আমরা বাংলা হাতের লেখা চিনতে ও পড়তে পারবে এরকম একটি শক্তিশালী কৃত্রিম বুদ্ধিমত্তা (এআই) তৈরি করতে চাই।",
    "ekush.ai ওয়েবসাইটে হাতের লেখা জমা দিয়ে যারা অবদান রাখছে, তারা স্বেচ্ছায় একটি চুক্তিতে প্রবেশ করছে যার মাধ্যমে তারা অবহিত আছে,",
    "১) প্রজেক্ট একুশ -এ যারা যে ছবি দিচ্ছেন, তারা নিশ্চিত করছেন যে ছবিটি তার নিজের এবং ছবিটি দেয়ার অধিকার তার রয়েছে",
    "২) তারা সম্পূর্ণরূপে স্বেচ্ছায় এবং বিনামূল্যে কাজটি করছেন, তারা পুরোপুরি অবহিত আছেন যে এই পুরো ডেটাসেটটি ওপেন সোর্স করে দেয়া হবে, এবং এই প্রচেষ্টা থেকে উদ্ভূত কিংবা এই ডেটাসেট ব্যবহার করে আমাদের বা যেকোনো তৃতীয় পক্ষের দ্বারা তৈরি ভবিষ্যত কোনো কাজের জন্য তারা কোন আর্থিক প্রতিদান দাবি করতে পারবেন না",

    "৩) তারা কখনো তাদের অবদান প্রত্যাহার করার দাবি রাখতে পারবেন না -এবং তারা ভবিষ্যতে দাবি করতে পারবেন না যে তাদের কাজ কোন কারণে ব্যবহার করা যাবে না",
  ],
};

export default content;
