import { makeStyles } from "@material-ui/core";
const useStyles = makeStyles((theme) => ({
  termsContainer: {
    textAlign: "left",
    fontFamily: "'Montserrat'",
    marginBottom: 200,
  },
}));

export default function TermsCondition() {
  const classes = useStyles();
  return (
    <div className={classes.termsContainer}>
      <p>
        <strong>TERMS AND CONDITIONS</strong>
      </p>
      <p style={{ marginTop: 0 }}>
        <br />
        By submitting the handwriting to <strong>ekush.ai</strong> website, you
        are entering into an agreement, by your actions, that you understand
        completely:
      </p>
      <p>
        <strong>1. Agreement to Terms</strong>
      </p>
      <p>
        1.1 These Terms and Conditions constitute a legally binding agreement
        made between you, whether personally or on behalf of an entity (“you”)
        and Intelligent Machines Ltd ((“we,” “us” or “our”), concerning your
        access to and use of the “ekush.ai” website as well as any other media
        form, media channel, mobile website or mobile application related,
        linked, or otherwise connected thereto (collectively, the “Site”).{" "}
      </p>
      <p>
        1.2 You agree that by accessing the Site, you have read, understood, and
        agree to be bound by all of these Terms and Conditions. If you do not
        agree with all of these Terms and Conditions, then you are expressly
        prohibited from using the Site and you must discontinue use immediately.
      </p>
      <p>
        1.3 Supplemental terms and conditions or documents that may be posted on
        the Site from time to time are hereby expressly incorporated herein by
        reference. We reserve the right, in our sole discretion, to make changes
        or modifications to these Terms and Conditions at any time and for any
        reason. It is your responsibility to periodically review these Terms and
        Conditions to stay informed of updates. You will be subject to, and will
        be deemed to have been made aware of and to have accepted, the changes
        in any revised Terms and Conditions by your continued use of the Site
        after the date such revised Terms and Conditions are posted.{" "}
      </p>
      <p>
        1.4 The information provided on the Site is not intended for
        distribution to or use by any person or entity in any jurisdiction or
        country where such distribution or use would be contrary to law or
        regulation or which would subject us to any registration requirement
        within such jurisdiction or country. Accordingly, those persons who
        choose to access the Site from other locations do so on their own
        initiative and are solely responsible for compliance with local laws, if
        and to the extent local laws are applicable.
      </p>
      <p>
        <strong>2. User Representations</strong>
      </p>
      <p>
        {" "}
        By using the Site, you represent and warrant that: (i) all information
        you submit will be true, accurate, current, and complete; (ii) you have
        the legal capacity and you agree to comply with these Terms and
        Conditions; (iii) not a minor in the jurisdiction in which you reside,
        or if a minor, you have received parental permission to use the Site;
        (iv) you will not access the Site through automated or non-human means,
        whether through a bot, script, or otherwise; (v) you will not use the
        Site for any illegal or unauthorized purpose; (vi) your use of the Site
        will not violate any applicable law or regulation.
        <br />
      </p>
      <p>
        <strong>3. User Generated Contributions</strong>
      </p>
      <p>
        3.1 The Site may provide you with the opportunity to create, submit,
        post, transmit, publish, or broadcast content and materials to us or on
        the Site, including but not limited to text, writings, video, audio,
        photographs, graphics, comments, suggestions, or other material
        (collectively, “Contributions”).{" "}
      </p>
      <p>
        3.2 Contributions may be viewable by other users of the Site and through
        third-party websites. As such, any Contributions you transmit may be
        treated as non-confidential and non-proprietary. When you create or make
        available any Contributions, you thereby represent and warrant that:
      </p>
      <p>
        {" "}
        (i) the creation, distribution, transmission, public display, or
        performance, and the accessing, downloading, or copying of your
        Contributions do not and will not infringe the proprietary rights,
        including but not limited to the copyright, patent, trademark, trade
        secret, or moral rights of any third party;
      </p>
      <p>
        {" "}
        (ii) you are the creator and owner of or have the necessary licenses,
        rights, consents, releases, and permissions to use and to authorize us,
        the Site, and other users of the Site to use your Contributions in any
        manner contemplated by the Site and these Terms and Conditions;
      </p>
      <p>
        {" "}
        (iii) you have the written consent, release, and/or permission of each
        and every identifiable individual person in your Contributions to use
        the name or likeness of each and every such identifiable individual
        person to enable inclusion and use of your Contributions in any manner
        contemplated by the Site and these Terms and Conditions;
      </p>
      <p>
        {" "}
        (iv) your Contributions are not unsolicited or unauthorized advertising,
        promotional materials, pyramid schemes, chain letters, spam, mass
        mailings, or other forms of solicitation;
      </p>
      <p>
        {" "}
        (v) your Contributions do not violate any applicable law, regulation, or
        rule or do not violate the privacy or publicity rights of any third
        party or do not contain any material that solicits personal information
        from anyone under the age of 18 or exploits people under the age of 18
        in a sexual or violent manner;
      </p>
      <p>
        {" "}
        (vi) your Contributions do not include any offensive comments that are
        connected to race, national origin, gender, sexual preference, or
        physical handicap;
      </p>
      <p>
        {" "}
        (vii) your Contributions do not otherwise violate, or link to material
        that violates, any provision of these Terms and Conditions, or any
        applicable law or regulation;
      </p>
      <p>
        3.3 Any use of the Site in violation of the foregoing violates these
        Terms and Conditions and may result in, among other things, termination
        or suspension of your rights to use the Site.{" "}
      </p>
      <p>
        <strong>4. Contribution License</strong>
      </p>
      <p>
        4.1 By posting your Contributions to any part of the Site, you
        automatically grant, and you represent and warrant that you have the
        right to grant, to us an unrestricted, unlimited, irrevocable,
        perpetual, non-exclusive, transferable, royalty-free, worldwide right,
        and license to host, use, copy, reproduce, disclose, sell, resell,
        publish, broadcast, retitle, archive, store, cache, publicly perform,
        publicly display, reformat, translate, transmit, excerpt (in whole or in
        part) or develop a product, and distribute such Contributions
        (including, without limitation, your image and voice) for any purpose,
        commercial, advertising, or otherwise, and to prepare derivative works
        of, or incorporate into other works, such Contributions, and grant and
        authorize sublicenses of the foregoing. The use and distribution may
        occur in any media formats and through any media channels.{" "}
      </p>
      <p>
        4.2 This license will apply to any form, media, or technology now known
        or hereafter developed, and includes our use of your name, company name,
        and franchise name, as applicable, and any of the trademarks, service
        marks, trade names, logos, and personal and commercial images you
        provide. You waive all moral rights in your Contributions, and you
        warrant that moral rights have not otherwise been asserted in your
        Contributions.{" "}
      </p>
      <p>
        4.3 You are solely responsible for your Contributions to the Site and
        you expressly agree to exonerate us from any and all responsibility and
        to refrain from any legal action against us regarding your
        Contributions.{" "}
      </p>
      <p>
        4.4 You are not entitled to any income or a share of profits (if any) or
        to participate in any incentive or similar arrangement as a result of
        your Contribution. This Terms and Conditions are not intended to and
        shall not be construed to create an employment relationship between you
        and us and as such no profit, salary, consultancy fee, pensions,
        allowances or other benefits are or will be payable by us to you.{" "}
      </p>
      <p>
        4.5 We have the right, in our sole and absolute discretion, (i) to edit,
        redact, or otherwise change any Contributions; (ii) to re-categorize any
        Contributions to place them in more appropriate locations on the Site;
        and (iii) to pre-screen or delete any Contributions at any time and for
        any reason, without notice. We have no obligation to monitor your
        Contributions.
      </p>
      <p>
        <strong>5. Prohibited Activities</strong>
      </p>
      <p>
        5.1 You may not access or use the Site for any purpose other than that
        for which we make the Site available. The Site may not be used in
        connection with any commercial endeavors except those that are
        specifically endorsed or approved by us.{" "}
      </p>
      <p>5.2 As a user of the Site, you agree not to: </p>
      <p>
        {" "}
        (i) systematically retrieve data or other content from the Site to
        create or compile, directly or indirectly, a collection, compilation,
        database, or directory without written permission from us;{" "}
      </p>
      <p>
        {" "}
        (ii) make any unauthorized use of the Site, including collecting
        usernames and/or email addresses of users by electronic or other means
        for the purpose of sending unsolicited email, or creating user accounts
        by automated means or under false pretenses;{" "}
      </p>
      <p>
        {" "}
        (iii) circumvent, disable, or otherwise interfere with security-related
        features of the Site, including features that prevent or restrict the
        use or copying of any Content or enforce limitations on the use of the
        Site and/or the Content contained therein;{" "}
      </p>
      <p>
        {" "}
        (iv) trick, defraud, or mislead us and other users, especially in any
        attempt to learn sensitive account information such as user passwords;{" "}
      </p>
      <p>
        {" "}
        (v) make improper use of our support services or submit false
        information of abuse or misconduct;{" "}
      </p>
      <p>
        {" "}
        (vi) interfere with, disrupt, or create an undue burden on the Site or
        the networks or services connected to the Site;{" "}
      </p>
      <p>
        {" "}
        (vii) use the Site as part of any effort to compete with us or otherwise
        use the Site and/or the Content for any revenue-generating endeavor or
        commercial enterprise;{" "}
      </p>
      <p>
        {" "}
        (viii) decipher, decompile, disassemble, or reverse engineer any of the
        software comprising or in any way making up a part of the Site;{" "}
      </p>
      <p>
        {" "}
        (ix) attempt to bypass any measures of the Site designed to prevent or
        restrict access to the Site, or any portion of the Site;{" "}
      </p>
      <p>
        {" "}
        (x) harass, annoy, intimidate, or threaten any of our employees or
        agents engaged in providing any portion of the Site to you;{" "}
      </p>
      <p>
        {" "}
        (xi) upload or transmit (or attempt to upload or to transmit) viruses,
        Trojan horses, or other material, including excessive use of capital
        letters and spamming (continuous posting of repetitive text), that
        interferes with any party’s uninterrupted use and enjoyment of the Site
        or modifies, impairs, disrupts, alters, or interferes with the use,
        features, functions, operation, or maintenance of the Site;{" "}
      </p>
      <p>
        {" "}
        (xii) upload or transmit (or attempt to upload or to transmit) any
        material that acts as a passive or active information collection or
        transmission mechanism, including without limitation, clear graphics
        interchange formats (“gifs”), web bugs, cookies, or other similar
        devices (sometimes referred to as “spyware” or “passive collection
        mechanisms” or “pcms”);{" "}
      </p>
      <p>
        {" "}
        (xiii) except as may be the result of standard search engine or Internet
        browser usage, use, launch, develop, or distribute any automated system,
        including without limitation, any spider, robot, cheat utility, scraper,
        or offline reader that accesses the Site, or using or launching any
        unauthorized script or other software;
      </p>
      <p>
        {" "}
        (xiv) disparage, tarnish, or otherwise harm, in our opinion, us and/or
        the Site;
      </p>
      <p>
        {" "}
        (xv) use the Site in a manner inconsistent with any applicable laws or
        regulations.
      </p>
      <p>
        <strong>6. Intellectual Property Rights</strong>
      </p>
      <p>
        6.1 Unless otherwise indicated, the Site is our proprietary property and
        all source code, databases, functionality, software, website designs,
        audio, video, text, photographs, and graphics on the Site (collectively,
        the “Content”) and the trademarks, service marks, and logos contained
        therein (the “Marks”) are owned or controlled by us or licensed to us,
        and are protected by copyright and trademark laws and various other
        intellectual property rights and unfair competition laws, foreign
        jurisdictions, and international conventions.{" "}
      </p>
      <p>
        6.2 The Content and the Marks are provided on the Site “AS IS” for your
        information and personal use only. Except as expressly provided in these
        Terms and Conditions, no part of the Site and no Content or Marks may be
        copied, reproduced, aggregated, republished, uploaded, posted, publicly
        displayed, encoded, translated, transmitted, distributed, sold,
        licensed, or otherwise exploited for any commercial purpose whatsoever,
        without our express prior written permission.
      </p>
      <p>
        <strong>7. Mobile Application License</strong>
      </p>
      <p>
        7.1 If you access the Site via a mobile application, then we grant you a
        revocable, non-exclusive, non-transferable, limited right to install and
        use the mobile application on wireless electronic devices owned or
        controlled by you, and to access and use the mobile application on such
        devices strictly in accordance with the terms and conditions of this
        mobile application license contained in these Terms and Conditions.{" "}
      </p>
      <p>7.2 You shall not: </p>
      <p>
        {" "}
        (i) decompile, reverse engineer, disassemble, attempt to derive the
        source code of, or decrypt the application;{" "}
      </p>
      <p>
        {" "}
        (ii) make any modification, adaptation, improvement, enhancement,
        translation, or derivative work from the application;{" "}
      </p>
      <p>
        {" "}
        (iii) violate any applicable laws, rules, or regulations in connection
        with your access or use of the application;{" "}
      </p>
      <p>
        {" "}
        (iv) remove, alter, or obscure any proprietary notice (including any
        notice of copyright or trademark) posted by us or the licensors of the
        application;{" "}
      </p>
      <p>
        {" "}
        (v) use the application for any revenue generating endeavor, commercial
        enterprise, or other purpose for which it is not designed or intended;{" "}
      </p>
      <p>
        {" "}
        (vi) make the application available over a network or other environment
        permitting access or use by multiple devices or users at the same time;{" "}
      </p>
      <p>
        {" "}
        (vii) use the application for creating a product, service, or software
        that is, directly or indirectly, competitive with or in any way a
        substitute for the application;{" "}
      </p>
      <p>
        {" "}
        (viii) use the application to send automated queries to any website or
        to send any unsolicited commercial e-mail;{" "}
      </p>
      <p>
        {" "}
        (ix) use any proprietary information or any of our interfaces or our
        other intellectual property in the design, development, manufacture,
        licensing, or distribution of any applications, accessories, or devices
        for use with the application.
      </p>
      <p>7.3 Apple and Android Devices</p>
      <p>
        {" "}
        The following terms apply when you use a mobile application obtained
        from either the Apple Store or Google Play (each an “App Distributor”)
        to access the Site:{" "}
      </p>
      <p>
        {" "}
        (i) the license granted to you for our mobile application is limited to
        a non-transferable license to use the application on a device that
        utilizes the Apple iOS or Android operating systems, as applicable, and
        in accordance with the usage rules set forth in the applicable App
        Distributor’s terms of service;{" "}
      </p>
      <p>
        {" "}
        (ii) we are responsible for providing any maintenance and support
        services with respect to the mobile application as specified in the
        terms and conditions of this mobile application license contained in
        these Terms and Conditions or as otherwise required under applicable
        law, and you acknowledge that each App Distributor has no obligation
        whatsoever to furnish any maintenance and support services with respect
        to the mobile application;{" "}
      </p>
      <p>
        {" "}
        (iii) in the event of any failure of the mobile application to conform
        to any applicable warranty, you may notify the applicable App
        Distributor, and the App Distributor, in accordance with its terms and
        policies, may refund the purchase price, if any, paid for the mobile
        application, and to the maximum extent permitted by applicable law, the
        App Distributor will have no other warranty obligation whatsoever with
        respect to the mobile application;{" "}
      </p>
      <p>
        {" "}
        (iv) you must comply with applicable third-party terms of agreement when
        using the mobile application, e.g., if you have a VoIP application, then
        you must not be in violation of their wireless data service agreement
        when using the mobile application;{" "}
      </p>
      <p>
        {" "}
        (v) you acknowledge and agree that the App Distributors are third-party
        beneficiaries of the terms and conditions in this mobile application
        license contained in these Terms and Conditions, and that each App
        Distributor will have the right (and will be deemed to have accepted the
        right) to enforce the terms and conditions in this mobile application
        license contained in these Terms and Conditions against you as a
        third-party beneficiary thereof.{" "}
      </p>
      <p>
        <strong>8. Third-Party Websites and Content</strong>
      </p>
      <p>
        8.1 The Site may contain (or you may be sent via the Site) links to
        other websites (“Third-Party Websites”) as well as articles,
        photographs, text, graphics, pictures, designs, music, sound, video,
        information, applications, software, and other content or items
        belonging to or originating from third parties (“Third-Party Content”).{" "}
      </p>
      <p>
        8.2 Such Third-Party Websites and Third-Party Content are not
        investigated, monitored, or checked for accuracy, appropriateness, or
        completeness by us, and we are not responsible for any Third-Party
        Websites accessed through the Site or any Third-Party Content posted on,
        available through, or installed from the Site, including the content,
        accuracy, offensiveness, opinions, reliability, privacy practices, or
        other policies of or contained in the Third-Party Websites or the
        Third-Party Content.{" "}
      </p>
      <p>
        8.3 Inclusion of, linking to, or permitting the use or installation of
        any Third-Party Websites or any Third-Party Content does not imply
        approval or endorsement thereof by us. If you decide to leave the Site
        and access the Third-Party Websites or to use or install any Third-Party
        Content, you do so at your own risk, and you should be aware these Terms
        and Conditions no longer govern.{" "}
      </p>
      <p>
        8.4 You should review the applicable terms and policies, including
        privacy and data gathering practices, of any website to which you
        navigate from the Site or relating to any applications you use or
        install from the Site. Any purchases you make through Third-Party
        Websites will be through other websites and from other companies, and we
        take no responsibility whatsoever in relation to such purchases which
        are exclusively between you and the applicable third party.{" "}
      </p>
      <p>
        8.5 You agree and acknowledge that we do not endorse the products or
        services offered on Third-Party Websites and you shall hold us harmless
        from any harm caused by your purchase of such products or services.
        Additionally, you shall hold us harmless from any losses sustained by
        you or harm caused to you relating to or resulting in any way from any
        Third-Party Content or any contact with Third-Party Websites.
      </p>

      <p>
        <strong>9. Site Management</strong>
      </p>
      <p> We reserve the right, but not the obligation, to: </p>
      <p>
        {" "}
        (i) monitor the Site for violations of these Terms and Conditions;{" "}
      </p>
      <p>
        {" "}
        (ii) take appropriate legal action against anyone who, in our sole
        discretion, violates the law or these Terms and Conditions, including
        without limitation, reporting such user to law enforcement authorities;{" "}
      </p>
      <p>
        {" "}
        (iii) in our sole discretion and without limitation, refuse, restrict
        access to, limit the availability of, or disable (to the extent
        technologically feasible) any of your Contributions or any portion
        thereof;{" "}
      </p>
      <p>
        {" "}
        (iv) in our sole discretion and without limitation, notice, or
        liability, to remove from the Site or otherwise disable all files and
        content that are excessive in size or are in any way burdensome to our
        systems;{" "}
      </p>
      <p>
        {" "}
        (v) otherwise manage the Site in a manner designed to protect our rights
        and property and to facilitate the proper functioning of the Site.
      </p>
      <p>
        <strong>10. Modifications and Interruptions </strong>
      </p>
      <p>
        10.1 We reserve the right to change, modify, or remove the contents of
        the Site at any time or for any reason at our sole discretion without
        notice. However, we have no obligation to update any information on our
        Site. We also reserve the right to modify or discontinue all or part of
        the Site without notice at any time.{" "}
      </p>
      <p>
        10.2 We will not be liable to you or any third party for any
        modification, price change, suspension, or discontinuance of the Site.{" "}
      </p>
      <p>
        10.3 We cannot guarantee the Site will be available at all times. We may
        experience hardware, software, or other problems or need to perform
        maintenance related to the Site, resulting in interruptions, delays, or
        errors.{" "}
      </p>
      <p>
        10.4 We reserve the right to change, revise, update, suspend,
        discontinue, or otherwise modify the Site at any time or for any reason
        without notice to you. You agree that we have no liability whatsoever
        for any loss, damage, or inconvenience caused by your inability to
        access or use the Site during any downtime or discontinuance of the
        Site.{" "}
      </p>
      <p>
        10.5 Nothing in these Terms and Conditions will be construed to obligate
        us to maintain and support the Site or to supply any corrections,
        updates, or releases in connection therewith.
      </p>
      <p>
        <strong>11. Disclaimer</strong>
      </p>
      <p>
        {" "}
        The site is provided on an as-is and as-available basis. You agree that
        your use of the site and our services will be at your sole risk. To the
        fullest extent permitted by law, we disclaim all warranties, express or
        implied, in connection with the site and your use thereof, including,
        without limitation, the implied warranties of merchantability, fitness
        for a particular purpose, and non-infringement. We make no warranties or
        representations about the accuracy or completeness of the site’s content
        or the content of any websites linked to the site and we will assume no
        liability or responsibility for any (i) errors, mistakes, or
        inaccuracies of content and materials; (ii) personal injury or property
        damage, of any nature whatsoever, resulting from your access to and use
        of the site; (iii) any unauthorized access to or use of our secure
        servers and/or any and all personal information and/or financial
        information stored therein; (iv) any interruption or cessation of
        transmission to or from the site; (v) any bugs, viruses, Trojan horses,
        or the like which may be transmitted to or through the site by any third
        party, and/or (vi) any errors or omissions in any content and materials
        or for any loss or damage of any kind incurred as a result of the use of
        any content posted, transmitted, or otherwise made available via the
        site. We do not warrant, endorse, guarantee, or assume responsibility
        for any product or service advertised or offered by a third party
        through the site, any hyperlinked website, or any website or mobile
        application featured in any banner or other advertising, and we will not
        be a party to or in any way be responsible for monitoring any
        transaction between you and any third-party providers of products or
        services.
      </p>
      <p>
        <strong>12. Indemnification</strong>
      </p>
      <p>
        12.1 You agree to defend, indemnify, and hold us harmless, including our
        subsidiaries, affiliates, and all of our respective officers, agents,
        partners, and employees, from and against any loss, damage, liability,
        claim, or demand, including reasonable attorneys’ fees and expenses,
        made by any third party due to or arising out of: (i) your
        Contributions; (ii) use of the Site; (iii) breach of these Terms and
        Conditions; (iv) any breach of your representations and warranties set
        forth in these Terms and Conditions; (v) your violation of the rights of
        a third party, including but not limited to intellectual property
        rights; or (vi) any overt harmful act toward any other user of the Site
        with whom you connected via the Site.{" "}
      </p>
      <p>
        12.2 Notwithstanding the foregoing, we reserve the right, at your
        expense, to assume the exclusive defense and control of any matter for
        which you are required to indemnify us, and you agree to cooperate, at
        your expense, with our defense of such claims. We will use reasonable
        efforts to notify you of any such claim, action, or proceeding which is
        subject to this indemnification upon becoming aware of it.{" "}
      </p>
      <p> </p>
      <p>
        <strong>13. User Data</strong>
      </p>
      <p>
        13.1 We will maintain certain data that you transmit to the Site for the
        purpose of managing the Site, as well as data relating to your use of
        the Site. Although we perform regular routine backups of data, you are
        solely responsible for all data that you transmit or that relates to any
        activity you have undertaken using the Site.{" "}
      </p>
      <p>
        13.2 You agree that we shall have no liability to you for any loss or
        corruption of any such data, and you hereby waive any right of action
        against us arising from any such loss or corruption of such data.
      </p>
      <p> </p>
      <p>
        <strong>
          14. Electronic Communications, Transactions, and Signatures
        </strong>
      </p>
      <p>
        14.1 Visiting the Site, sending us emails, completing online forms and
        making Submissions constitute electronic communications. You consent to
        receive electronic communications, and you agree that all agreements,
        notices, disclosures, and other communications we provide to you
        electronically, via email and on the Site, satisfy any legal requirement
        that such communication be in writing.{" "}
      </p>
      <p>
        14.2 You hereby waive any rights or requirements under any statutes,
        regulations, rules, ordinances, or other laws in any jurisdiction which
        require an original signature or delivery or retention of non-electronic
        records, or to payments or the granting of credits by any means other
        than electronic means.
      </p>
      <p>
        <strong>15. Term and Termination</strong>
      </p>
      <p>
        {" "}
        These Terms and Conditions shall remain in full force and effect while
        you use the Site. Without limiting any other provision of these terms
        and conditions, we reserve the right to, in our sole discretion and
        without notice or liability, deny access to and use of the Site
        (including blocking certain IP addresses), to any person for any reason
        or for no reason, including without limitation for breach of any
        representation, warranty, or covenant contained in these terms and
        conditions or of any applicable law or regulation. We may terminate your
        use or participation in the Site or delete any content or information
        that you posted at any time, without warning, in our sole discretion.{" "}
      </p>
      <p>
        <strong>16. Governing Law </strong>
      </p>
      <p>
        {" "}
        These Terms and Conditions and your use of the Site are governed by and
        construed in accordance with the laws of Bangladesh, without regard to
        its conflict of law principles.{" "}
      </p>
      <p>
        <strong>17. Dispute Resolution</strong>
      </p>
      <p>
        17.1 Informal Negotiations. To expedite resolution and control the cost
        of any dispute, controversy, or claim related to these Terms and
        Conditions (each a “Dispute” and collectively, the “Disputes”) brought
        by either you or us (individually, a “Party” and collectively, the
        “Parties”), the Parties agree to first attempt to negotiate any Dispute
        informally for at least 30 days before initiating arbitration. Such
        informal negotiations commence upon written notice from one Party to the
        other Party.
      </p>
      <p>
        17.2 If the Parties are unable to resolve a Dispute through informal
        negotiations, the Dispute will be finally and exclusively resolved by
        binding arbitration under the Arbitration Act, 2001. Each Party shall
        appoint its own arbitrator and the two arbitrators so appointed shall
        appoint a third arbitrator who shall act as the Chairman of the Arbitral
        Tribunal. Only retired judges of the High Court Division of the Supreme
        Court of Bangladesh and Senior Advocates of the Supreme Court of
        Bangladesh shall be eligible for appointment as arbitrators. Each Party
        shall bear one half the cost of the arbitration filing and hearing fees,
        and the cost of the arbitrator. The arbitration shall be conducted in
        the English language and the place of arbitration shall be Dhaka,
        Bangladesh.
      </p>
      <p>
        17.3 If a Party hereto can obtain an award of damages or an injunction
        resulting from a breach of any provision hereof, such Party may also
        recover its costs, legal fees, and the like as additional compensation
        for such breach.
      </p>
      <p>
        <strong>18. Miscellaneous</strong>
      </p>
      <p>
        18.1 These Terms and Conditions and any policies or operating rules
        posted by us on the Site constitute the entire agreement and
        understanding between you and us. Our failure to exercise or enforce any
        right or provision of these Terms and Conditions shall not operate as a
        waiver of such right or provision.{" "}
      </p>
      <p>
        18.2 These Terms and Conditions operate to the fullest extent
        permissible by law. We may assign any or all of our rights and
        obligations to others at any time. We shall not be responsible or liable
        for any loss, damage, delay, or failure to act caused by any cause
        beyond our reasonable control.{" "}
      </p>
      <p>
        18.3 If any provision or part of a provision of these Terms and
        Conditions is determined to be unlawful, void, or unenforceable, that
        provision or part of the provision is deemed severable from these Terms
        and Conditions and does not affect the validity and enforceability of
        any remaining provisions.{" "}
      </p>
      <p>
        18.4 There is no joint venture, partnership, employment or agency
        relationship created between you and us as a result of these Terms and
        Conditions or use of the Site. You agree that these Terms and Conditions
        will not be construed against us by virtue of having drafted them.{" "}
      </p>
      <p>
        18.5 You hereby waive any and all defenses you may have based on the
        electronic form of these Terms and Conditions and the lack of signing by
        the parties hereto to execute these Terms and Conditions.
      </p>
      <p>
        <strong>-------- End of user agreement --------</strong>
      </p>
    </div>
  );
}
