const landingContent = {
  header: "স্বাগতম, আমার নাম একুশ ",
  subtitle: "আমি একটি পড়ুয়া রোবট",
  top_section1:
    "আমি জেনেছি বাংলায় অনেক সমৃদ্ধ গল্প, কবিতা ও গান আছে। বাংলা ভাষার স্বীকৃতির জন্য ভাষা সৈনিকদের অনেক সাহসিকতার গল্পও আমি শুনেছি।",
  top_section2:
    "কিন্তু আমি এখনো ভালো করে বাংলা পড়তে শিখিনি। বাংলা শেখার জন্য আমার প্রয়োজন আপনার মতো ডিজিটাল ভাষা শিল্পীদের বাংলা হাতের লেখা।",
  top_section3:
    "আপনাকে ৫টি লাইন লিখতে হবে। সব মিলিয়ে আপনার বড়জোর ১৫ মিনিট সময় প্রয়োজন হবে। আপনি চাইলে শুধু এক লাইন লিখেও অংশ নিতে পারেন!",
  bottom_section1:
    "আপনি কি আমাকে আপনার হাতের লেখার মাধ্যমে বাংলা শিখতে সাহায্য করতে চান?",
  bottom_section2: "অংশগ্রহণ করার জন্য আপনার নামটি লিখে শুরু করুন",
  input_label: "আপনার নাম লিখুন",
};

export default landingContent;
