import { makeStyles } from "@material-ui/core";
import {
  // EmailShareButton,
  // EmailIcon,
  // FacebookMessengerShareButton,
  // FacebookMessengerIcon,
  FacebookShareButton,
  FacebookIcon,
  LinkedinShareButton,
  LinkedinIcon,
  WhatsappShareButton,
  WhatsappIcon,
} from "react-share";

const useStyles = makeStyles((theme) => ({
  buttonContiner: {
    padding: theme.spacing(0.5),
    border: "0.5px solid rgba(36, 37, 38, 0.2)",
    height: 40,
    width: 40,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 8,
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(0.5),
    "&:hover": {
      backgroundColor: "rgba(36, 37, 38, 0.2)",
    },
  },
}));

function IconWrapper({ component: Component }) {
  const classes = useStyles();
  return (
    <div className={classes.buttonContiner}>
      <Component size={32} round={true} />
    </div>
  );
}

const ShareBar = ({ urlString }) => {
  return (
    <>
      <FacebookShareButton
        url={urlString}
        children={<IconWrapper component={FacebookIcon} />}
      />
      {/* <FacebookMessengerShareButton
        url={urlString}
        children={<IconWrapper component={FacebookMessengerIcon} />}
      /> */}
      <LinkedinShareButton
        url={urlString}
        children={<IconWrapper component={LinkedinIcon} />}
      />
      <WhatsappShareButton
        url={urlString}
        children={<IconWrapper component={WhatsappIcon} />}
      />
      {/* <EmailShareButton
        url={urlString}
        children={<IconWrapper component={EmailIcon} />}
      /> */}
    </>
  );
};

export default ShareBar;
