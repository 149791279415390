import routes from "../../_routing";

const initState = { pageTracker: routes };

const pageTrackingReducer = (state = initState, action) => {
  switch (action.type) {
    case "PAGETRACK":
      return {
        ...state,
        pageTracker: action.payload,
      };

    case "RESET_PAGETRACK":
      return {
        ...state,
        pageTracker: routes,
      };

    default:
      return state;
  }
};

export default pageTrackingReducer;
