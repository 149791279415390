const initState = { testState: null };

export const testReducer = (state = initState, action) => {
  switch (action.type) {
    case "TESTING_DISPATCH":
      return {
        ...state,
        testState: action.payload,
      };

    default:
      return state;
  }
};
