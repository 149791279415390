import AWS from "aws-sdk";
import { awsConfig } from "../const";

export const uploadBlobToS3 = async (data, filename) => {
  let s3bucket = new AWS.S3({
    accessKeyId: awsConfig.IAM_key,
    secretAccessKey: awsConfig.IAM_secret,
    Bucket: awsConfig.bucket,
  });

  const k = Date.now().toLocaleString();
  var params = {
    Bucket: awsConfig.bucket,
    Key: k + "--" + data.name,
    Body: data,
    ACL: awsConfig.permission,
  };

  let res = await s3bucket.upload(params).promise();
  return res.Location;
};
