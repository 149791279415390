import { Grow, makeStyles } from "@material-ui/core";
import StarBlue from "../../../assets/svgs/StarBlue";
import Tick from "../../../assets/svgs/Tick";
const useStyles = makeStyles((theme) => ({
  starRatingContainer: {
    height: 70,
    width: 40,
    borderRadius: 32,
    margin: 5,
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    flexDirection: "column",
    [theme.breakpoints.down("sm")]: {
      height: "fit-content",
      padding: 5,
    },

    "& p": {
      fontSize: 10,
      margin: 0,
      [theme.breakpoints.down("sm")]: {
        marginTop: 4,
      },
    },
  },
  tick: {
    position: "absolute",
    zIndex: 40,
    left: "50%",
    transform: "translate(-50%, -15px)",
  },
}));
const levelScores = ["+২৫", "+৭৫", "+২২৫", "+৫৭৫", "+৯৯৯"];

export default function Ratings({ isVisible, completedLevel }) {
  const classes = useStyles();
  const delay = 300;

  return (
    <div style={{ display: "flex" }}>
      {levelScores.map((key, index) => {
        return (
          <div style={{ position: "relative" }} key={key + index}>
            {
              <div className={classes.tick}>
                <Grow
                  in={index < completedLevel && isVisible}
                  timeout={{
                    enter: index === 0 ? (key.length + 1) * delay + 1200 : 1000,
                    exit: 200,
                  }}
                >
                  <div>
                    <Tick />
                  </div>
                </Grow>
              </div>
            }
            <Grow
              in={isVisible}
              timeout={{ enter: 1000 + index * delay, exit: 500 }}
              style={{ transformOrigin: "0 0 0" }}
            >
              <div>
                {" "}
                <StarRating
                  score={key}
                  completed={index < completedLevel}
                  isCurrentLevel={completedLevel === index}
                />
              </div>
            </Grow>
          </div>
        );
      })}
    </div>
  );
}

function StarRating({ score, completed, isCurrentLevel }) {
  const classes = useStyles();
  return (
    <div
      className={classes.starRatingContainer}
      style={{
        backgroundColor: completed ? "rgba(54, 43, 115, 1)" : "white",
        border: isCurrentLevel
          ? "2px solid rgba(54, 43, 115, 1)"
          : "2px solid white",
      }}
    >
      <StarBlue color={!completed ? "rgba(54, 43, 115, 1)" : "white"} />
      <p style={{ color: !completed ? "rgba(54, 43, 115, 1)" : "white" }}>
        {score}
      </p>
    </div>
  );
}
