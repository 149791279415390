const LeftArrow = () => {
  return (
    <svg
      width="8"
      height="12"
      viewBox="0 0 8 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.28982 5.99999L6.82982 2.45999C7.01607 2.27263 7.12061 2.01918 7.12061 1.75499C7.12061 1.49081 7.01607 1.23736 6.82982 1.04999C6.73686 0.956266 6.62626 0.881872 6.5044 0.831103C6.38254 0.780334 6.25183 0.754196 6.11982 0.754196C5.98781 0.754196 5.8571 0.780334 5.73524 0.831103C5.61339 0.881872 5.50278 0.956266 5.40982 1.04999L1.16982 5.28999C1.07609 5.38296 1.0017 5.49356 0.950931 5.61542C0.900162 5.73728 0.874023 5.86798 0.874023 5.99999C0.874023 6.13201 0.900162 6.26271 0.950931 6.38457C1.0017 6.50643 1.07609 6.61703 1.16982 6.70999L5.40982 11C5.50326 11.0927 5.61408 11.166 5.73592 11.2158C5.85775 11.2655 5.98821 11.2908 6.11982 11.29C6.25143 11.2908 6.38189 11.2655 6.50373 11.2158C6.62557 11.166 6.73638 11.0927 6.82982 11C7.01607 10.8126 7.12061 10.5592 7.12061 10.295C7.12061 10.0308 7.01607 9.77736 6.82982 9.58999L3.28982 5.99999Z"
        fill="#CD2633"
      />
    </svg>
  );
};

export default LeftArrow;
