const initState = { uploading: false };

const uploadingReducer = (state = initState, action) => {
  switch (action.type) {
    case "IMAGE_UPLOAD_STATE":
      return {
        ...state,
        uploading: action.payload,
      };

    default:
      return state;
  }
};

export default uploadingReducer;
