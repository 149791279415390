import React from "react";
import {
  Collapse,
  Container,
  Grow,
  useMediaQuery,
  useTheme,
  Button,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import { connect } from "react-redux";

import { currentPageAction } from "../../redux/actions/currentPageAction";
import { pageTrackingAction } from "../../redux/actions/pageTrackingAction";
import findNextPageOf from "../../utils/findNextPageOf";
import InputText from "../common/InputText";
import landingContent from "./content";
import robotHi from "../../assets/robotHi.svg";
import Footer from "../Footer";
import { resetLevelAction } from "../../redux/actions/compeltedLevelAction";
import Back from "../../assets/svgs/Back";

import { saveUser } from "../../network/services";

const useStyles = makeStyles((theme) => ({
  landingContianer: {
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down("sm")]: {
      padding: 0,
    },
  },
  topSection: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      padding: theme.spacing(2),
    },
  },
  header: {
    color: "#CD2633",
    [theme.breakpoints.down("sm")]: {
      fontSize: 26,
      marginTop: theme.spacing(2),
    },
  },

  subtitle: {
    [theme.breakpoints.down("sm")]: {
      fontSize: 20,
    },
  },
  top_section1: {
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  imageContainer: {
    display: "flex",
    padding: 10,
    justifyContent: "center",
    alignItems: "center",
    "& img": {
      borderRadius: "50%",
      filter: "contrast(1.2)",
      height: 216,
      width: 212,
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: "-5%",
      padding: 0,
      "& img": {
        height: 180,
        width: 180,
      },
    },
  },
  topSectionContent: {
    textAlign: "left",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    marginLeft: theme.spacing(1),
    width: "60%",
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0,
      width: "100%",
      textAlign: "center",
      alignItems: "center",
    },
    "& h2": {
      marginTop: 0,
    },
  },
  readMoreButton: {
    fontFamily: "Noto Sans Bengali",
    fontWeight: 300,
    fontSize: 18,
    color: "rgba(205, 38, 51, 1)",
    borderRadius: 110,
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    transform: "scale(.8)",
    width: "60vw",
  },
  collapseIcon: { display: "flex", marginLeft: 10 },

  returnSection: {
    padding: "8px 15px 8px 15px",
    background: "white",
    borderRadius: 110,
    fontWeight: 600,
    width: "fit-content",
    cursor: "none",
    textAlign: "center",
    border: "1px solid #95a5a78a",
    [theme.breakpoints.down("sm")]: {
      fontSize: 15,
    },
    //color: "rgba(54, 43, 115, 1)",
  },
  ekush_placeholder: {
    marginLeft: 3,
    marginRight: 3,
    fontWeight: 400,
    fontSize: 20,
    [theme.breakpoints.down("sm")]: {
      fontSize: 17,
      fontWeight: 600,
    },
  },
  bottomSection: {
    textAlign: "center",
    background: "rgba(54, 43, 115, .1)",
    borderRadius: "80px",
    padding: theme.spacing(5),
    backdropFilter: "blur(14px)",
    [theme.breakpoints.down("sm")]: {
      borderRadius: 0,
      padding: theme.spacing(2),
      paddingTop: theme.spacing(2),
    },
  },
  bottom_section1: {
    fontSize: 22,
    fontWeight: 600,
    width: "60%",
    margin: "auto",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      fontSize: 20,
    },
  },
  bottom_section2: {
    fontSize: 17,
    fontWeight: 500,
    width: "60%",
    margin: "auto",
    paddingTop: 5,
    marginTop: theme.spacing(1),
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      fontSize: 17,
    },
  },
  bottom_section3: {
    fontSize: 17,
    fontWeight: 400,
    width: "70%",
    margin: "auto",
    marginTop: theme.spacing(1),
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      fontSize: 17,
    },
  },
  inputField: {
    width: "60%",
    margin: "auto",
    marginTop: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  section_2: {
    fontWeight: 500,
  },
  returnSectionContainerForMobile: {
    display: "flex",
    justifyContent: "center",
  },
}));

function Landing({
  setPageTracking,
  setCurrentPage,
  pageTrackerData,
  resetLevel,
}) {
  const classes = useStyles();
  const theme = useTheme();
  const match = useMediaQuery(theme.breakpoints.down("sm"));
  const [userName, setUserName] = React.useState("");
  const [inputError, setInputError] = React.useState(false);
  const [isTopSectionHidden, setIsTopSectionHidden] = React.useState(false);

  React.useEffect(() => {
    // if (pageTrackerData["landing"].data)
    //   setUserName(pageTrackerData["landing"].data.userName);
    setPageTracking("RESET");
    resetLevel();
  }, [setPageTracking, resetLevel]);

  const onChangePage = () => {
    //for saving page data
    if (userName === "") {
      setInputError(true);
      return;
    }

    saveUser({ name: userName })
      .then((result) => {
        const { data } = result;
        const newPageTrackerData = {
          ...pageTrackerData,
          landing: {
            ...pageTrackerData["landing"],
            data: { userName, userId: data.id },
            completed: true,
          },
        };

        setPageTracking(newPageTrackerData);
        setCurrentPage(findNextPageOf("landing"));
      })
      .catch((err) => console.log(err));

    //for setting next page of the flow
  };

  function handleFormChange(event) {
    setUserName(event.target.value);

    if (userName !== "") {
      setInputError(false);
    }
  }

  function handleAdvanceClick(e) {
    e.preventDefault();
    onChangePage();
  }

  return (
    <Container className={classes.landingContianer} maxWidth="md">
      <div className={classes.topSection}>
        <div className={classes.imageContainer}>
          <Grow in={true} timeout={1000}>
            <img src={robotHi} alt="robothellogit" />
          </Grow>
        </div>
        <div className={classes.topSectionContent}>
          <h1 className={classes.header}>{landingContent.header}</h1>
          <h2 className={classes.subtitle}>{landingContent.subtitle}</h2>
          {match && (
            <Button
              onClick={() => setIsTopSectionHidden(!isTopSectionHidden)}
              className={classes.readMoreButton}
              style={{ background: "#d0cfdb57" }}
            >
              {!isTopSectionHidden ? "আরো পড়ুন" : "গুটিয়ে ফেলুন"}
              <div
                className={classes.collapseIcon}
                style={{
                  transform: isTopSectionHidden
                    ? "rotate(90deg)"
                    : "rotate(270deg)",
                }}
              >
                <Back />
              </div>
            </Button>
          )}
          {match ? (
            <Collapse in={isTopSectionHidden} timeout={1000}>
              <div>
                <p className={classes.top_section1}>
                  {landingContent.top_section1} {landingContent.top_section2}
                </p>
                <p>{landingContent.top_section3}</p>
              </div>
            </Collapse>
          ) : (
            <div>
              <p className={classes.top_section1}>
                {landingContent.top_section1}
              </p>

              <p>{landingContent.top_section2}</p>
            </div>
          )}
        </div>
      </div>
      <div className={classes.bottomSection}>
        <p className={classes.bottom_section1}>
          {landingContent.bottom_section1}
        </p>
        {!match && (
          <p className={classes.bottom_section3}>
            {landingContent.top_section3}
          </p>
        )}
        <p className={classes.bottom_section2}>
          {landingContent.bottom_section2}
        </p>
        <div className={classes.inputField}>
          <InputText
            onAdvance={handleAdvanceClick}
            onFormChange={handleFormChange}
            label={landingContent.input_label}
            inputError={inputError}
            value={userName}
          />
        </div>
      </div>
      <Footer />
    </Container>
  );
}

const mapStateToProps = (state) => {
  return {
    pageTrackerData: state.pageTracking.pageTracker,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setPageTracking: (data) => dispatch(pageTrackingAction(data)),
    setCurrentPage: (data) => dispatch(currentPageAction(data)),
    resetLevel: () => dispatch(resetLevelAction()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Landing);
