import axios from "axios";
const baseUrl = "https://api.ekush.ai/api/v1";

const api = axios.create({
  baseURL: baseUrl,
});

api.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    return Promise.resolve(config);
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

export default api;
