import * as React from "react";
import { makeStyles } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import ArrowForwardIcon from "../../assets/svgs/ArrowForwardIcon";
import { IconButton } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  textField: {
    fontSize: 14,
    background: "#FFFFFF",
    width: "100%",

    [`& fieldset`]: {
      borderRadius: 100,
      border: "0px solid red",
    },
    "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
      background: "white",
      padding: 4,
      borderRadius: 5,
      transform: "translate(14px, -11px) scale(0.75)",
      display: "none",
    },

    "& .MuiInputBase-root": {
      "& .MuiOutlinedInput-notchedOutline": {
        "& legend": {
          maxWidth: 0,
        },
      },
    },
  },
  textFieldInput: {
    fontSize: 18,
    color: "rgba(54, 43, 115, 1)",
    background: "white",
    borderRadius: 100,
    marginLeft: 10,
    fontWeight: 500,
    letterSpacing: 1.5,
  },
  textFieldLabel: {
    // color: alpha(theme.palette.primary.main, 0.9),
    textAlign: "initial",
    fontSize: 15,
    fontFamily: "Noto Sans Bengali",
    margin: 0,
    marginLeft: 10,
    marginTop: 5,
  },
  advancedButton: {
    padding: theme.spacing(2),

    cursor: "pointer",
    "& :hover": {
      transform: "scale(1.05)",
    },
  },
}));

function InputText({ onFormChange, onAdvance, label, inputError, value }) {
  const classes = useStyles();

  return (
    <form onSubmit={onAdvance}>
      <TextField
        label={value === "" ? label : ""}
        error={inputError}
        id="outlined-end-adornment"
        type={"text"}
        variant="outlined"
        size="medium"
        value={value}
        onChange={onFormChange}
        spellCheck={false}
        className={classes.textField}
        style={{ borderRadius: 110 }}
        autoComplete="off"
        InputLabelProps={{
          className: classes.textFieldLabel,
        }}
        InputProps={{
          classes: {
            input: classes.textFieldInput,
          },
          endAdornment: (
            <InputAdornment position="end" className={classes.advancedButton}>
              <IconButton type="submit">
                <ArrowForwardIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </form>
  );
}

export default InputText;
