import React from "react";
import { Collapse, Container, makeStyles } from "@material-ui/core";
import { Grow, useMediaQuery, useTheme } from "@material-ui/core";
import logo from "../../assets/Vector.png";
import ekush from "../../assets/ekush.svg";
import Ratings from "./components/ratings";
import { ClickAwayListener } from "@material-ui/core";
import clsx from "clsx";
import routingOfAbouts from "../../_routingOfAbouts";

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    position: "sticky",
  },

  navBarContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    paddingTop: 20,
    paddingBottom: 20,
    alignItems: "center",
    width: "100%",
    height: "12vh",

    [theme.breakpoints.down("sm")]: {
      padding: 0,
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      flexDirection: "row-reverse",
      heihgt: "inherit",
    },
  },
  brand: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    filter: "grayscale(1)",
    cursor: "pointer",

    "& img": {
      width: 32,
      height: 32,
    },
    "& p": {
      fontSize: 16,
      marginTop: 2,
      textAlign: "left",
      marginLeft: 5,
      fontWeight: 600,
      color: "rgba(54, 43, 115, 1)",
      lineHeight: "16px",
    },
  },
  ekushLogo: {
    display: "flex",
    alignItems: "center",
    height: 50,
    cursor: "pointer",
    position: "relative",
    "& img": {
      width: 32,
      height: 32,
    },
  },
  returnSection: {
    padding: "8px 16px 8px 16px",
    background: "white",
    borderRadius: 110,
    fontWeight: 600,
    width: "fit-content",
    cursor: "pointer",
    textAlign: "center",
    border: "none",
    margin: 0,
    marginLeft: 40,
    position: "absolute",
    zIndex: 40,
    display: "flex",

    [theme.breakpoints.down("sm")]: {
      fontSize: 15,
      position: "relative",
      width: "100vw",
      marginLeft: 0,
      justifyContent: "center",
    },

    //color: "rgba(54, 43, 115, 1)",
  },
  ekush_placeholder: {
    margin: "0 8px",
    fontSize: 16,
    fontWeight: 500,
    width: "max-content",
    color: "rgba(54, 43, 115, 1)",
    "&:hover": {
      color: "rgba(205, 38, 51, 1)",
    },
  },

  ekush_placeholder_active: {
    color: "rgba(205, 38, 51, 1)",
  },
  userName: {
    marginTop: 9,
    textAlign: "left",
    letterSpacing: 0.6,

    [theme.breakpoints.down("sm")]: {
      fontSize: 11,
      marginTop: 5,
      backdropFilter: "blur(1px)",

      textAlign: "center",
    },
  },
}));

function ReturnSection({
  handleAboutPage,
  currentAboutPage,
  isShowingAboutPage,
}) {
  const classes = useStyles();
  const handleSpanClick = (e) => {
    e.stopPropagation();
    handleAboutPage(e.target.id);
  };
  return (
    <Grow in={true}>
      <p className={classes.returnSection}>
        {Object.keys(routingOfAbouts).map((keyItem) => {
          return (
            <span
              key={keyItem}
              id={routingOfAbouts[keyItem].id}
              onClick={handleSpanClick}
              className={
                currentAboutPage === routingOfAbouts[keyItem].id &&
                isShowingAboutPage
                  ? clsx(
                      classes.ekush_placeholder,
                      classes.ekush_placeholder_active
                    )
                  : classes.ekush_placeholder
              }
            >
              {routingOfAbouts[keyItem].label}
            </span>
          );
        })}
      </p>
    </Grow>
  );
}

export default function NavBar(props) {
  const theme = useTheme();
  const match = useMediaQuery(theme.breakpoints.down("sm"));
  const classes = useStyles(props);
  const [isHovered, setHoverFlag] = React.useState(true);
  React.useEffect(() => {
    setHoverFlag(
      props.currentPage === "landing" ? true : props.isShowingAboutPage
    );
    //setHoverFlag(props.isShowingAboutPage);
  }, [props.currentPage, match, props.isShowingAboutPage]);

  function toggleHover(hoverFlag) {
    setHoverFlag(hoverFlag);
    //console.log(hoverFlag);
  }

  function onClickAwayReturnSection(e) {
    if (props.isShowingAboutPage) return;
    toggleHover(false);
  }

  function greetingUser(userName) {
    const time = new Date().getHours();
    let greeting;

    if (time < 12) {
      greeting = `শুভ সকাল, ${userName}!`;
    } else if (time < 15) {
      greeting = `শুভ দুপুর, ${userName}!`;
    } else if (time < 17) {
      greeting = `শুভ বিকাল, ${userName}!`;
    } else if (time < 19) {
      greeting = `শুভ সন্ধ্যা, ${userName}!`;
    } else {
      greeting = `শুভ রাত্রি, ${userName}!`;
    }

    return greeting;
  }

  return (
    <Container className={classes.container} maxWidth="md">
      <div className={classes.navBarContainer}>
        <ClickAwayListener onClickAway={onClickAwayReturnSection}>
          <div
            className={classes.ekushLogo}
            onMouseEnter={() => {
              if (match) return;
              toggleHover(true);
            }}
            onClick={() => toggleHover(!isHovered)}
          >
            <img src={ekush} alt="ekush" />

            {isHovered && !match && (
              <ReturnSection
                handleAboutPage={props.handleAboutPage}
                currentAboutPage={props.currentAboutPage}
                isShowingAboutPage={props.isShowingAboutPage}
              />
            )}
          </div>
        </ClickAwayListener>
        {!match && (
          <div style={{ opacity: isHovered ? 0.1 : 1 }}>
            <Ratings
              isVisible={props.currentPage !== "landing"}
              completedLevel={props.completedLevel}
            />
          </div>
        )}
        <div>
          <div
            className={classes.brand}
            id="aboutIM"
            onClick={() => props.handleAboutPage("aboutIM")}
          >
            <img src={logo} alt="logo" />
            {
              <p>
                ইন্টেলিজেন্ট <br />
                মেশিন্স
              </p>
            }
          </div>
          {!match && props.trackingData["landing"].data && (
            <Grow in={true} timeout={1000}>
              <p className={classes.userName}>
                {greetingUser(
                  capitalizeFirstLetter(
                    props.trackingData["landing"].data.userName
                  )
                )}
              </p>
            </Grow>
          )}
        </div>
      </div>
      {match && (
        <Collapse in={isHovered}>
          <ReturnSection
            handleAboutPage={props.handleAboutPage}
            currentAboutPage={props.currentAboutPage}
            isShowingAboutPage={props.isShowingAboutPage}
          />
        </Collapse>
      )}
      {props.currentPage !== "landing" && (
        <Collapse in={match && !props.isShowingAboutPage && !isHovered}>
          <div style={{ opacity: isHovered ? 0.1 : 1, paddingBottom: 5 }}>
            <Ratings
              isVisible={props.currentPage !== "landing"}
              completedLevel={props.completedLevel}
            />
          </div>
        </Collapse>
      )}
      {match && props.trackingData["landing"].data && (
        <div style={{ width: "100%" }}>
          <p className={classes.userName}>
            {greetingUser(
              capitalizeFirstLetter(props.trackingData["landing"].data.userName)
            )}
          </p>
        </div>
      )}
    </Container>
  );
}
