const Copy = () => {
  return (
    <svg
      width="19"
      height="20"
      viewBox="0 0 19 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.9941 18H5.99414C5.19849 18 4.43543 17.6839 3.87282 17.1213C3.31021 16.5587 2.99414 15.7956 2.99414 15V5C2.99414 4.73478 2.88878 4.48043 2.70125 4.29289C2.51371 4.10536 2.25936 4 1.99414 4C1.72892 4 1.47457 4.10536 1.28703 4.29289C1.0995 4.48043 0.994141 4.73478 0.994141 5V15C0.994141 16.3261 1.52092 17.5979 2.45861 18.5355C3.39629 19.4732 4.66806 20 5.99414 20H13.9941C14.2594 20 14.5137 19.8946 14.7012 19.7071C14.8888 19.5196 14.9941 19.2652 14.9941 19C14.9941 18.7348 14.8888 18.4804 14.7012 18.2929C14.5137 18.1054 14.2594 18 13.9941 18ZM18.9941 6.94C18.9837 6.84813 18.9636 6.75763 18.9341 6.67V6.58C18.8861 6.47718 18.8219 6.38267 18.7441 6.3L12.7441 0.3C12.6615 0.222216 12.567 0.158081 12.4641 0.11H12.3741L12.0541 0H7.99414C7.19849 0 6.43543 0.316071 5.87282 0.87868C5.31021 1.44129 4.99414 2.20435 4.99414 3V13C4.99414 13.7956 5.31021 14.5587 5.87282 15.1213C6.43543 15.6839 7.19849 16 7.99414 16H15.9941C16.7898 16 17.5529 15.6839 18.1155 15.1213C18.6781 14.5587 18.9941 13.7956 18.9941 13V7C18.9941 7 18.9941 7 18.9941 6.94ZM12.9941 3.41L15.5841 6H13.9941C13.7289 6 13.4746 5.89464 13.287 5.70711C13.0995 5.51957 12.9941 5.26522 12.9941 5V3.41ZM16.9941 13C16.9941 13.2652 16.8888 13.5196 16.7012 13.7071C16.5137 13.8946 16.2594 14 15.9941 14H7.99414C7.72892 14 7.47457 13.8946 7.28703 13.7071C7.0995 13.5196 6.99414 13.2652 6.99414 13V3C6.99414 2.73478 7.0995 2.48043 7.28703 2.29289C7.47457 2.10536 7.72892 2 7.99414 2H10.9941V5C10.9941 5.79565 11.3102 6.55871 11.8728 7.12132C12.4354 7.68393 13.1985 8 13.9941 8H16.9941V13Z"
        fill="#CD2633"
      />
    </svg>
  );
};

export default Copy;
