import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";

import { makeStyles } from "@material-ui/core/styles";
import { Button, Grow, useMediaQuery, useTheme } from "@material-ui/core";
import robotCamera from "../../assets/robotCamera.svg";
import AddNewFile from "./../../assets/icons/AddNewFile";
import Upload from "./../../assets/icons/Upload";
import AddNewFileRed from "./../../assets/icons/AddNewFileRed";
import ImageIcon from "./../../assets/icons/ImageIcon";
import Close from "./../../assets/icons/Close";
import Camera from "../../assets/svgs/Camera";

const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
  },
  initialPlaceHolderContainer: {
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      paddingBottom: 20,
    },
  },
  imageContainer: {
    marginRight: theme.spacing(3),
    height: 179,
    width: 179,
    [theme.breakpoints.down("sm")]: {
      transform: "scale(.7)",
      marginTop: -30,
    },
  },
  uploadButton: {
    background: "rgba(205, 38, 51, 1)",
    borderRadius: 45,
    border: "none",
    padding: theme.spacing(1.5),
    cursor: "pointer",
    width: "fit-content",
    paddingRight: theme.spacing(2.5),
    margin: theme.spacing(1),
    marginLeft: 0,
    "& .MuiButton-label": {
      marginTop: 0,
      marginLeft: 5,
    },

    "&:hover": {
      backgroundColor: "#cd2634e6",
    },
  },
  uploadText: {
    fontSize: 18,
    fontWeight: 500,
    textAlign: "left",
    marginBottom: theme.spacing(1),
    marginLeft: 5,
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
      width: "80%",
    },
  },
  uploadButtonContainer: {
    display: "flex",
    alignItems: "center",
    marginLeft: theme.spacing(1),
  },
  addButton: {
    background: "#FFFFFF",
    borderRadius: 45,
    border: "1px solid #9B1D20",
    padding: theme.spacing(1.5),
    cursor: "pointer",
    width: "fit-content",
    paddingRight: theme.spacing(2.5),
    margin: theme.spacing(1),
  },
  addButtonText: {
    textTransform: "none",
    fontSize: 18,
    fontWeight: 500,
    color: "#9B1D20",
    marginRight: theme.spacing(2),
    fontFamily: "Noto Sans Bengali",
  },
  buttonContainer: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      alignItems: "center",
    },
  },
  buttonGuide: {
    display: "flex",
    flexDirection: "column",
    width: "fit-content",
    alignItems: "flex-start",
    [theme.breakpoints.down("sm")]: {
      alignItems: "center",
    },
  },

  uploadButtonText: {
    textTransform: "none",
    marginTop: 0,
    fontSize: 18,
    fontWeight: 400,
    color: "#FFFFFF",
    marginRight: theme.spacing(2),
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontFamily: "Noto Sans Bengali",
  },
  dragText: {
    fontSize: 13,
    fontWeight: 400,
    width: "fit-content",
    opacity: 0.8,
    textAlign: "left",
    marginLeft: theme.spacing(1),
  },
  dragText2: {
    fontSize: 13,
    fontWeight: 400,
    opacity: 0.8,
    textAlign: "center",
    marginLeft: theme.spacing(1),
  },
  imageIcon: {
    height: 64,
    width: 64,
    borderRadius: "50%",
    background: "rgba(155, 29, 32, 0.1)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  imageIconContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexWrap: "wrap",
  },
  imageWrapper: {
    margin: theme.spacing(2),
    height: "80px",
    width: "80px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
  },
  closeIcon: {
    position: "absolute",
    zIndex: 30,
    top: 0,
    right: "10%",
  },
  closeIconWrapper: {
    cursor: "pointer",
  },
  imageName: {
    overflow: "hidden",
    fontSize: 14,
    fontFamily: "Montserrat",
    width: "max-content",
    color: "rgba(36, 37, 38, 1)",
  },
  orParagraraph: { textAlign: "center", margin: 0, fontSize: 12 },
}));

function InitialPlaceHolder({ open, isActive, highest }) {
  const theme = useTheme();
  const match = useMediaQuery(theme.breakpoints.down("sm"));
  const classes = useStyles();
  return (
    <div className={classes.initialPlaceHolderContainer}>
      <div className={classes.imageContainer}>
        <img src={robotCamera} alt="robothellogit" />
      </div>
      <div className={classes.buttonContainer}>
        <h1 className={classes.uploadText}>
          লেখা হয়ে গেলে, এবার লেখাটির একটি স্পষ্ট ছবি তুলে আপলোড করুন
        </h1>
        <div className={classes.buttonGuide}>
          {match && (
            <Button
              className={classes.uploadButton}
              onClick={open}
              disabled={!isActive}
            >
              <p className={classes.uploadButtonText}>ছবি তুলুন</p>
              <Camera />
            </Button>
          )}
          {match && <p className={classes.orParagraraph}>অথবা</p>}
          <Button
            className={classes.uploadButton}
            onClick={open}
            disabled={!isActive}
          >
            <p className={classes.uploadButtonText}>ফাইল সিলেক্ট করুন</p>
            <AddNewFile />
          </Button>
          {match && highest && (
            <h1 className={classes.dragText2}>
              <span style={{ color: "red" }}>সর্বোচ্চ ৩ টি ফাইল</span>
            </h1>
          )}
          {!match && (
            <h1 className={classes.dragText}>
              অথবা ফাইলটি টেনে এখানে আনুন <br /> (ড্র্যাগ এন্ড ড্রপ করুন)
              {highest && (
                <span style={{ color: "red" }}>
                  <br />
                  সর্বোচ্চ ৩ টি ফাইল
                </span>
              )}
            </h1>
          )}
        </div>
      </div>
    </div>
  );
}

function CustomFileIcon({ name, index, onClose }) {
  function handleClose(imageIndex) {
    onClose(imageIndex);
  }
  const classes = useStyles();
  return (
    <div key={index} className={classes.imageWrapper}>
      <div className={classes.closeIcon}>
        <span
          className={classes.closeIconWrapper}
          onClick={() => handleClose(index)}
        >
          <Close />
        </span>
      </div>
      <div className={classes.imageIcon}>
        <ImageIcon />
      </div>
      <div className={classes.imageName}>
        {name.length > 9 ? name.substring(0, 6) + "..." : name}
      </div>
    </div>
  );
}

function UploadPlaceHolder({ open, onSubmit, fileList, onClose, highest }) {
  function handleClose(imageIndex) {
    onClose(imageIndex);
  }
  const classes = useStyles();
  return (
    <div>
      <div className={classes.imageIconContainer}>
        {fileList.map((file, index) => (
          <div key={file.path + "_" + index}>
            <Grow in={true}>
              <div>
                <CustomFileIcon
                  name={file.path}
                  index={index}
                  onClose={handleClose}
                />
              </div>
            </Grow>
          </div>
        ))}
      </div>
      {highest && (
        <h1 className={classes.dragText2}>
          <span style={{ color: "red" }}>সর্বোচ্চ ৩ টি ফাইল</span>
        </h1>
      )}
      <div>
        <button className={classes.addButton} onClick={open}>
          <div className={classes.uploadButtonContainer}>
            <div className={classes.addButtonText}>ফাইল যোগ করুন</div>
            <AddNewFileRed />
          </div>
        </button>
        <button className={classes.uploadButton} onClick={onSubmit}>
          <div className={classes.uploadButtonContainer}>
            <div className={classes.uploadButtonText}>আপলোড করুন</div>
            <Upload />
          </div>
        </button>
      </div>
    </div>
  );
}

function FileUpload({ onSubmit, isActive }) {
  const classes = useStyles();
  const [fileList, setFileList] = React.useState([]);
  const [highest, setHighest] = React.useState(false);

  const onDrop = useCallback(
    (acceptedFiles) => {
      if (acceptedFiles.length + fileList.length <= 3) {
        setFileList([...fileList, ...acceptedFiles]);
        setHighest(false);
      } else {
        setHighest(true);
      }
    },
    [fileList]
  );

  const { getRootProps, getInputProps, open } = useDropzone({
    // Disable click and keydown behavior
    noClick: true,
    noKeyboard: true,
    onDrop,
    accept: "image/*",
    maxSize: 50 * 1024 * 1024,
  });

  function handleSubmit() {
    onSubmit(fileList);
  }

  function handleClose(imageIndex) {
    const filteredFiles = fileList.filter(
      (file, index) => index !== imageIndex
    );
    setFileList([...filteredFiles]);
  }

  return (
    <div className={classes.container}>
      <div {...getRootProps({ className: "dropzone" })}>
        <input {...getInputProps()} />
        {fileList.length === 0 ? (
          <InitialPlaceHolder
            open={open}
            isActive={isActive}
            highest={highest}
          />
        ) : (
          <UploadPlaceHolder
            open={open}
            onSubmit={handleSubmit}
            fileList={fileList}
            onClose={handleClose}
            highest={highest}
          />
        )}
      </div>
    </div>
  );
}

export default FileUpload;
