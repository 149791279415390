const StarBlue = ({ color }) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20 7.66999C19.9368 7.48708 19.822 7.32642 19.6693 7.20749C19.5167 7.08857 19.3328 7.01649 19.14 6.99999L13.45 6.16999L10.9 0.999993C10.8181 0.830922 10.6902 0.688335 10.5311 0.588566C10.3719 0.488798 10.1878 0.435883 9.99998 0.435883C9.81212 0.435883 9.62806 0.488798 9.46889 0.588566C9.30971 0.688335 9.18186 0.830922 9.09998 0.999993L6.54998 6.15999L0.859977 6.99999C0.6749 7.0263 0.500902 7.10396 0.357728 7.22415C0.214554 7.34435 0.107937 7.50227 0.0499774 7.67999C-0.00307759 7.85367 -0.00783865 8.03851 0.0362052 8.21469C0.080249 8.39086 0.171435 8.55172 0.299977 8.67999L4.42998 12.68L3.42998 18.36C3.39428 18.5475 3.41297 18.7412 3.48385 18.9184C3.55473 19.0956 3.67483 19.2489 3.82998 19.36C3.98119 19.4681 4.15955 19.5319 4.34503 19.5443C4.5305 19.5566 4.71575 19.5171 4.87998 19.43L9.99998 16.76L15.1 19.44C15.2403 19.5192 15.3988 19.5605 15.56 19.56C15.7718 19.5607 15.9784 19.4942 16.15 19.37C16.3051 19.2589 16.4252 19.1056 16.4961 18.9284C16.567 18.7512 16.5857 18.5575 16.55 18.37L15.55 12.69L19.68 8.68999C19.8244 8.56767 19.9311 8.4069 19.9877 8.22634C20.0444 8.04579 20.0486 7.85287 20 7.66999ZM13.85 11.67C13.7327 11.7834 13.645 11.9238 13.5944 12.079C13.5439 12.2341 13.532 12.3992 13.56 12.56L14.28 16.75L10.52 14.75C10.3753 14.673 10.2139 14.6327 10.05 14.6327C9.88606 14.6327 9.72466 14.673 9.57998 14.75L5.81998 16.75L6.53998 12.56C6.56791 12.3992 6.55609 12.2341 6.50554 12.079C6.45499 11.9238 6.36725 11.7834 6.24998 11.67L3.24998 8.66999L7.45998 8.05999C7.62198 8.03746 7.77598 7.97553 7.90848 7.87964C8.04098 7.78376 8.14794 7.65683 8.21998 7.50999L9.99998 3.69999L11.88 7.51999C11.952 7.66683 12.059 7.79376 12.1915 7.88964C12.324 7.98553 12.478 8.04746 12.64 8.06999L16.85 8.67999L13.85 11.67Z"
        fill={color}
      />
    </svg>
  );
};

export default StarBlue;
