import { createStore, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension/developmentOnly";
import _rootReducer from "./reducers/_rootReducer";
import thunk from "redux-thunk";

const middlewares = [thunk];

const store = createStore(
  _rootReducer,
  composeWithDevTools(applyMiddleware(...middlewares))
);

export default store;
