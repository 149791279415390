import AboutEkush from "./components/Abouts/aboutEkush";
import AboutIM from "./components/Abouts/aboutIM";
import AboutTerms from "./components/Abouts/aboutTerms";

const routingOfAbouts = {
  aboutEkush: {
    child: <AboutEkush />,
    id: "aboutEkush",
    label: "প্রজেক্ট একুশ",
  },
  aboutIM: {
    child: <AboutIM />,
    id: "aboutIM",
    label: "ইন্টেলিজেন্ট মেশিন্স ",
  },
  aboutTerms: {
    child: <AboutTerms />,
    id: "aboutTerms",
    label: "শর্তাবলী",
  },
};

export default routingOfAbouts;
