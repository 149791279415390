const textDisplayContent = {
  guide: "লাইনটি কোনো প্রকার বানান, ব্যাকরণ পরিবর্তন ছাড়াই লিখতে হবে।",
  disclaimer:
    "লেখাগুলো উইকিপিডিয়া থেকে নেয়া হয়েছে।  উইকিপিডিয়া টিমের প্রতি আমাদের আন্তরিক কৃতজ্ঞতা।",
  top_section1:
    "ইংলিশ প্রিমিয়ার লিগের সর্বশেষ ম্যাচে লিভারপুলের কাছে ৫ গোলে বিধ্বস্ত হয়েছিল ম্যানচেস্টার ইউনাইটেড। তবে নিজেদের দশম ম্যাচে এসে ঘুরে দাঁড়িয়েছে রেড ডেভিলরা। দলের বড় তারকা ক্রিস্তিয়ানো রোনালদোর নৈপুণ্যে ইউনাইটেড ৩-০ গোলে হারিয়েছে টটেনহাম হটস্পারকে। এছাড়া লা লিগায় এলচের বিপক্ষে ভিনিসিয়ুসের জোড়ায় ২-১ গোলে জিতেছে রিয়াল মাদ্রিদ।",
  top_section2:
    "প্রতিপক্ষের মাঠে বল দখলে এগিয়ে থেকে আক্রমণে গেছে ম্যানচেস্টার ইউনাইটেড। ম্যাচ ঘড়ির ২৫ মিনিটে কাভানির হেড পোস্টের বাইরে দিয়ে না গেলে তখনই এগিয়ে যেতে পারতো ওলেগানার সুলশারের দল।",
};

export default textDisplayContent;
