const MessageInfo = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.0001 11.75C12.6686 11.75 12.3506 11.8817 12.1162 12.1161C11.8818 12.3505 11.7501 12.6685 11.7501 13V16.75C11.7501 17.0815 11.8818 17.3995 12.1162 17.6339C12.3506 17.8683 12.6686 18 13.0001 18C13.3316 18 13.6495 17.8683 13.884 17.6339C14.1184 17.3995 14.2501 17.0815 14.2501 16.75V13C14.2501 12.6685 14.1184 12.3505 13.884 12.1161C13.6495 11.8817 13.3316 11.75 13.0001 11.75ZM13.0001 8C12.7529 8 12.5112 8.07331 12.3056 8.21066C12.1001 8.34801 11.9398 8.54324 11.8452 8.77165C11.7506 9.00005 11.7259 9.25139 11.7741 9.49386C11.8223 9.73634 11.9414 9.95907 12.1162 10.1339C12.291 10.3087 12.5137 10.4278 12.7562 10.476C12.9987 10.5242 13.25 10.4995 13.4784 10.4048C13.7068 10.3102 13.9021 10.15 14.0394 9.94446C14.1768 9.7389 14.2501 9.49723 14.2501 9.25C14.2501 8.91848 14.1184 8.60054 13.884 8.36612C13.6495 8.1317 13.3316 8 13.0001 8ZM13.0001 0.5C11.3586 0.5 9.7331 0.823322 8.21653 1.45151C6.69996 2.07969 5.32197 3.00043 4.16124 4.16117C1.81704 6.50537 0.500077 9.68479 0.500077 13C0.489149 15.8864 1.48857 18.6857 3.32508 20.9125L0.825077 23.4125C0.65163 23.5883 0.534134 23.8115 0.487417 24.054C0.440699 24.2965 0.466852 24.5474 0.562577 24.775C0.666399 24.9999 0.834707 25.1889 1.04614 25.318C1.25756 25.4471 1.50258 25.5104 1.75008 25.5H13.0001C16.3153 25.5 19.4947 24.183 21.8389 21.8388C24.1831 19.4946 25.5001 16.3152 25.5001 13C25.5001 9.68479 24.1831 6.50537 21.8389 4.16117C19.4947 1.81696 16.3153 0.5 13.0001 0.5V0.5ZM13.0001 23H4.76258L5.92508 21.8375C6.04319 21.7217 6.13715 21.5837 6.20152 21.4313C6.26589 21.279 6.29939 21.1154 6.30008 20.95C6.29538 20.6203 6.16059 20.3058 5.92508 20.075C4.28831 18.4401 3.26904 16.2882 3.04094 13.986C2.81284 11.6838 3.39 9.37376 4.67411 7.4494C5.95822 5.52505 7.86982 4.10545 10.0832 3.43247C12.2966 2.75948 14.6749 2.87476 16.8128 3.75864C18.9508 4.64253 20.7161 6.24034 21.8081 8.27986C22.9001 10.3194 23.2512 12.6744 22.8015 14.9438C22.3518 17.2131 21.1292 19.2563 19.342 20.7253C17.5548 22.1943 15.3135 22.9982 13.0001 23V23Z"
        fill="#1C1E21"
      />
    </svg>
  );
};

export default MessageInfo;
