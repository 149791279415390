import { Fade, makeStyles } from "@material-ui/core";
import aboutEkush from "../../assets/aboutEkush.png";
import aboutEkushMobile from "../../assets/aboutEkushMobile.png";
import { Button } from "@material-ui/core";
import Back from "../../assets/svgs/Back";
import content from "./content";

const useStyles = makeStyles((theme) => ({
  containerEkush: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    height: "100%",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  imageContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-end",
    flex: 1,
    backgroundImage: `url(${aboutEkush})`,
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
      backgroundImage: `url(${aboutEkushMobile})`,
      position: "sticky",
      top: 0,
      zIndex: 50,
    },
    "& h1": {
      fontSize: 64,
      fontWeight: 900,
      color: "rgba(255,255,255,.7)",
      textAlign: "right",
      marginRight: 40,
      [theme.breakpoints.down("sm")]: {
        fontSize: 35,
        marginTop: 20,
      },
    },
  },
  button: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-evenly",
    alignItems: "center",
    margin: 40,
    background: "white",
    fontFamily: "Noto Sans Bengali",
    color: "rgba(205, 38, 51, 1)",
    fontSize: 18,
    fontWeight: 500,
    borderRadius: 110,
    width: 116,
    height: 43,
    [theme.breakpoints.down("sm")]: {
      margin: 0,
      transform: "scale(.7)",
      background: "rgba(255,255,255,.5)",
    },
  },
  contentContainer: {
    flex: 2,
    overflowY: "auto",
    padding: 10,
    marginLeft: theme.spacing(5),
    paddingBottom: theme.spacing(10),
    paddingTop: 0,
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0,
      overflowY: "hidden",
    },
  },
  innerContetnContainer: {
    width: "80%",
    [theme.breakpoints.down("sm")]: { width: "100%", marginTop: 20 },
  },
  contentParagraph: {
    textAlign: "left",
    fontSize: 20,
    [theme.breakpoints.down("sm")]: {
      fontSize: 18,
    },
  },
  contentHeaderParagraph: {
    textAlign: "left",
    fontSize: 22,
    fontWeight: 500,
    marginTop: 40,
    [theme.breakpoints.down("sm")]: {
      marginTop: 0,
      fontSize: 20,
    },
  },
}));

export default function AboutEkush({ closeAboutPage }) {
  const classes = useStyles();
  return (
    <div className={classes.containerEkush}>
      <div className={classes.imageContainer}>
        <Button className={classes.button} onClick={closeAboutPage}>
          <Back />
          পিছনে
        </Button>
        <h1>
          প্রজেক্ট <br />
          একুশ
          <br /> সম্পর্কে
        </h1>
      </div>
      <div className={classes.contentContainer}>
        <div className={classes.innerContetnContainer}>
          {content.aboutEkush.map((item, index) => {
            return (
              <Fade in={true} key={index} timeout={1000 + 300 * index}>
                <p className={classes.contentParagraph}>{item}</p>
              </Fade>
            );
          })}
          <Fade in={true} key={"end"} timeout={1000 + 300 * 11}>
            <p className={classes.contentParagraph} style={{ marginTop: 2 }}>
              ইন্টেলিজেন্ট মেশিন্সের সবাই
            </p>
          </Fade>
        </div>
      </div>
    </div>
  );
}
