const initState = { completed: 0 };

const completedReducer = (state = initState, action) => {
  switch (action.type) {
    case "INC_COMPLETION":
      return {
        ...state,
        completed: state.completed + 1,
      };

    case "RESET_COMPLETION":
      return {
        ...state,
        completed: 0,
      };

    default:
      return state;
  }
};

export default completedReducer;
