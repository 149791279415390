import React from "react";
import { Collapse, Fade, makeStyles } from "@material-ui/core";
import aboutTerms from "../../assets/aboutTerms.png";
import aboutTermsMobile from "../../assets/aboutTermsMobile.png";
import { Button } from "@material-ui/core";
import Back from "../../assets/svgs/Back";
import content from "./content";
import TermsCondition from "./termsContent";

const useStyles = makeStyles((theme) => ({
  containerEkush: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    height: "100%",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  imageContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-end",
    flex: 1,
    backgroundImage: `url(${aboutTerms})`,
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
      backgroundImage: `url(${aboutTermsMobile})`,
      position: "sticky",
      top: 0,
      zIndex: 50,
    },
    "& h1": {
      fontSize: 64,
      fontWeight: 900,
      color: "rgba(255,255,255,.7)",
      textAlign: "right",
      marginRight: 40,

      [theme.breakpoints.down("sm")]: {
        fontSize: 35,
      },
    },
  },
  button: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-evenly",
    alignItems: "center",
    margin: 40,
    background: "white",
    fontFamily: "Noto Sans Bengali",
    color: "rgba(205, 38, 51, 1)",
    fontSize: 18,
    fontWeight: 500,
    borderRadius: 110,
    width: 116,
    height: 43,
    [theme.breakpoints.down("sm")]: {
      margin: 0,
      transform: "scale(.7)",
      background: "rgba(255,255,255,.5)",
    },
  },
  contentContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    flex: 2,
    overflowY: "auto",
    padding: 10,
    marginLeft: theme.spacing(5),
    paddingBottom: theme.spacing(10),
    paddingTop: 0,
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0,
      overflowY: "hidden",
    },
  },
  innerContetnContainer: {
    width: "80%",
    [theme.breakpoints.down("sm")]: { width: "100%", marginTop: 20 },
  },
  contentParagraph: {
    textAlign: "left",
    fontSize: 20,
    [theme.breakpoints.down("sm")]: {
      fontSize: 18,
    },
  },
  contentHeaderParagraph: {
    textAlign: "left",
    fontSize: 24,
    fontWeight: 600,
    marginTop: 40,
    [theme.breakpoints.down("sm")]: {
      marginTop: 20,
      fontSize: 20,
    },
  },
  readMoreButton: {
    fontFamily: "Noto Sans Bengali",
    fontWeight: 300,
    fontSize: 18,
    color: "rgba(205, 38, 51, 1)",
    borderRadius: 110,
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    marginTop: theme.spacing(5),
  },
  collapseIcon: { display: "flex", marginLeft: 10 },
}));

export default function AboutTerms({ closeAboutPage }) {
  const classes = useStyles();
  const [isTermsOpen, setTermsOpen] = React.useState(false);
  return (
    <div className={classes.containerEkush}>
      <div className={classes.imageContainer}>
        <Button className={classes.button} onClick={closeAboutPage}>
          <Back />
          পিছনে
        </Button>
        <h1>
          <br />
          শর্তাবলী
          <br />
          <br />
        </h1>
      </div>
      <div className={classes.contentContainer}>
        <div className={classes.innerContetnContainer}>
          {content.aboutTerms.map((item, index) => {
            return (
              <div key={index}>
                {index === 2 && (
                  <Fade in={true} key={"header"} timeout={1500}>
                    <p className={classes.contentHeaderParagraph}>মূলকথাঃ</p>
                  </Fade>
                )}
                <Fade in={true} key={index} timeout={1000 + 300 * index}>
                  <p className={classes.contentParagraph}>{item}</p>
                </Fade>
              </div>
            );
          })}
        </div>
        <Button
          onClick={() => setTermsOpen(!isTermsOpen)}
          className={classes.readMoreButton}
          style={{ background: "#d0cfdb57" }}
        >
          {!isTermsOpen ? "বিস্তারিত পড়ুন" : "গুটিয়ে ফেলুন"}
          <div
            className={classes.collapseIcon}
            style={{
              transform: isTermsOpen ? "rotate(90deg)" : "rotate(270deg)",
            }}
          >
            <Back />
          </div>
        </Button>
        <Collapse in={isTermsOpen}>
          <TermsCondition />
        </Collapse>
      </div>
    </div>
  );
}
