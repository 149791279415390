import * as React from "react";
import { Container, Grow } from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core";
import { Button } from "@material-ui/core";
import { connect } from "react-redux";
import { currentPageAction } from "../../redux/actions/currentPageAction";
import { pageTrackingAction } from "../../redux/actions/pageTrackingAction";
import findNextPageOf from "../../utils/findNextPageOf";
import robotHiSmall from "../../assets/robotHiSmall.svg";
import LeftArrow from "./../../assets/icons/LeftArrow";
import Footer from "../Footer";
import { getContributors } from "../../network/services";
import ekush from "../../assets/ekush.svg";
import LinearProgress from "@material-ui/core/LinearProgress";
import { toggleParticipentsView } from "../../redux/actions/footerAction";
import RightArrowRed from "./../../assets/icons/RightArrowRed";

const useStyles = makeStyles((theme) => ({
  container: {
    borderRadius: 20,
    background: "rgba(255,255,255,.3)",
    paddingTop: 20,
    paddingLeft: theme.spacing(7),
    paddingRight: theme.spacing(7),
    [theme.breakpoints.down("sm")]: {
      padding: 0,
      paddingTop: 10,
      marginTop: 5,
    },
  },
  imageContainer: {
    marginTop: -70,
    [theme.breakpoints.down("sm")]: {
      marginTop: -30,
    },
  },
  header: { fontSize: 24, fontWeight: 500, padding: 15 },
  buttonContainer: {
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      marginLeft: 12,
    },
  },
  contributorContainer: {
    display: "flex",
    flexWrap: "wrap",

    justifyContent: "center",
    margin: "auto",
  },
  contributorName: {
    padding: "6px 16px",
    margin: "4px 4px",
    background: "#FFFFFF",
    //border: "1px solid #4b4282",
    borderRadius: 32,
    fontWeight: 700,
    fontFamily: "Montserrat",
    color: "#00000094",
  },
  backButton: {
    background: "#FFFFFF",
    borderRadius: 20,
    border: "none",
    padding: theme.spacing(1.5),
    cursor: "pointer",
    width: "fit-content",
    paddingRight: theme.spacing(2.5),
    margin: theme.spacing(1),
    marginLeft: 0,
    color: "rgba(205, 38, 51, 1)",
    "& .MuiButton-label": {
      marginTop: 0,
      marginLeft: 5,
    },
  },
  backButtonText: {
    marginLeft: 16,
  },
  backButtonTextSeemore: {
    marginRight: 16,
  },
  topSectionDuring: {
    textAlign: "center",
    paddingBottom: theme.spacing(2),
    backdropFilter: "blur(24px)",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      borderRadius: "0",
      margin: theme.spacing(0),
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      padding: theme.spacing(2),
      paddingTop: 0,
    },
  },
  progressContainer: {
    width: "40%",
    margin: "auto",
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  imageContainerDuring: {
    display: "flex",
    padding: 10,
    justifyContent: "center",
    "& img": {
      filter: "contrast(1.2)",
      height: 120,
      width: 120,
    },
    [theme.breakpoints.down("sm")]: {
      "& img": {
        height: 120,
        width: 120,
      },
    },
  },
}));

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 10,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor:
      theme.palette.grey[theme.palette.type === "light" ? 200 : 700],
  },
  bar: {
    borderRadius: 5,
    backgroundColor: "rgba(205, 38, 51, 1)",
  },
}))(LinearProgress);

function During() {
  const classes = useStyles();
  return (
    <>
      <div className={classes.topSectionDuring}>
        <div className={classes.imageContainerDuring}>
          <img src={ekush} alt="robothellogit" />
        </div>
        <p className={classes.during}>অপেক্ষা করুন...</p>
        <div className={classes.progressContainer}>
          <BorderLinearProgress />
        </div>
      </div>
    </>
  );
}

function Participents({
  setPageTracking,
  setCurrentPage,
  pageTrackerData,
  showParticipentsView,
  toggleParticipentsView,
}) {
  const classes = useStyles();
  const [userList, setUserList] = React.useState([]);
  const [pagination, setPagination] = React.useState(1);
  const [fetching, setFetching] = React.useState(false);

  React.useEffect(() => {
    getContributors()
      .then((result) => {
        const { data } = result;
        setUserList(data);
      })
      .catch((err) => console.log(err));
  }, []);

  const onChangePage = () => {
    if (showParticipentsView) {
      toggleParticipentsView();
      return;
    }
    const newPageTrackerData = {
      ...pageTrackerData,
      participents: {
        ...pageTrackerData["participents"],
        completed: true,
      },
    };
    setPageTracking(newPageTrackerData);
    setCurrentPage(findNextPageOf("participents"));
  };

  function onSeeMore() {
    setFetching(true);

    getContributors(pagination)
      .then((result) => {
        const { data } = result;
        setUserList([...userList, ...data]);
        setPagination(pagination + 1);
        setFetching(false);
      })
      .catch((err) => console.log(err));
  }

  return (
    <Container maxWidth="md" className={classes.container}>
      <div className={classes.buttonContainer}>
        <Button className={classes.backButton} onClick={onChangePage}>
          <LeftArrow /> <span className={classes.backButtonText}>পিছনে</span>
        </Button>
      </div>
      {userList.length > 0 && (
        <>
          <div className={classes.imageContainer}>
            <Grow in={true} timeout={1000}>
              <img src={robotHiSmall} alt="robothellogit" />
            </Grow>
          </div>
          <div className={classes.headerContainer}>
            <h1 className={classes.header}>সকল সহযোগীর তালিকা</h1>
          </div>
          <div className={classes.contributorContainer}>
            {userList.map((names, index) => (
              <div key={index} className={classes.contributorName}>
                {names}
              </div>
            ))}
          </div>
          {fetching && <During />}
          <Button className={classes.backButton} onClick={onSeeMore}>
            <span className={classes.backButtonTextSeemore}>আরো দেখুন</span>
            <RightArrowRed />
          </Button>
        </>
      )}
      {userList.length === 0 && <During />}
      <Footer />
    </Container>
  );
}

const mapStateToProps = (state) => {
  return {
    pageTrackerData: state.pageTracking.pageTracker,
    showParticipentsView: state.footer.showParticipents,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setPageTracking: (data) => dispatch(pageTrackingAction(data)),
    setCurrentPage: (data) => dispatch(currentPageAction(data)),
    toggleParticipentsView: () => dispatch(toggleParticipentsView()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Participents);
