//import Gradient from "./assets/Gradient_50.png";
import React from "react";
import sohid_minar from "./assets/bg-100.png";
import gradient_color from "./assets/gradient_color.png";
import "./App.css";
//import { Fade } from "@material-ui/core";
import { makeStyles, Slide } from "@material-ui/core";
import NavBar from "./components/NavBar";

import routes from "./_routing";
import routingOfAbouts from "./_routingOfAbouts";
import { connect } from "react-redux";
import Participents from "./components/Participents";

const useStyles = makeStyles((theme) => ({
  App: {
    textAlign: "center",
    height: "100vh",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundImage: `url(${sohid_minar})`,
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    overflowX: "hidden",
    overflowY: (props) => (props.showAbout === true ? "hidden" : "auto"),

    [theme.breakpoints.down("sm")]: {
      backgroundSize: "auto",
      backgroundRepeat: "repeat",
      overflowY: (props) => (props.showAbout === true ? "auto" : "auto"),
    },
  },
  fadeContainer: {
    display: "flex",
    flex: 20,
    backgroundPosition: "initial",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    position: "relative",
    justifyContent: "center",

    backgroundImage: (props) =>
      props.currentPage === "textDisplay" && !props.showParticipentsView
        ? `url(${gradient_color})`
        : "none",
    width: (props) =>
      props.currentPage === "textDisplay" || props.about ? "100%" : "inherit",
  },
  aboutPageContainer: {
    background: "white",
    position: "absolute",
    zIndex: 50,
    width: "100%",
    height: "88vh",
    [theme.breakpoints.down("sm")]: {
      height: (props) => (props.showAbout === true ? "fit-content" : "88vh"),
    },
  },
}));

function App({
  currentPage,
  trackingData,
  completedLevel,
  showParticipentsView,
}) {
  const [about, setAbout] = React.useState("landing");
  const [showAbout, setShowAbout] = React.useState(false);
  var appRef = React.createRef();

  const classes = useStyles({
    currentPage,
    about,
    showAbout,
    showParticipentsView,
  });

  const handleAboutPage = (pageName) => {
    if (pageName === about && showAbout) {
      setShowAbout(false);
      return;
    }
    setAbout(pageName);
    appRef.current.scrollTo(0, 0);
    setShowAbout(true);
    //console.log(routingOfAbouts[pageName].child);
  };

  const closeAboutPage = () => {
    setShowAbout(false);
  };

  return (
    <div className={classes.App} ref={appRef}>
      <NavBar
        trackingData={trackingData}
        currentPage={currentPage}
        handleAboutPage={handleAboutPage}
        currentAboutPage={about}
        isShowingAboutPage={showAbout}
        completedLevel={completedLevel}
      />

      <div className={classes.fadeContainer}>
        {/*about pages. absolute positions. gives a modal vibe. with some funky old coding patterns */}
        <Slide in={showAbout} direction="left">
          <div className={classes.aboutPageContainer}>
            {routingOfAbouts[about]
              ? React.cloneElement(
                  routingOfAbouts[about].child,
                  { closeAboutPage },
                  null
                )
              : null}
          </div>
        </Slide>

        {/*Main flow of the pages */}
        {showParticipentsView && <Participents />}
        <div
          style={{
            display: showAbout || showParticipentsView ? "none" : "block",
          }}
        >
          {routes[currentPage].child}
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    currentPage: state.currentPage.currentPage,
    trackingData: state.pageTracking.pageTracker,
    completedLevel: state.completedLevel.completed,
    showParticipentsView: state.footer.showParticipents,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
