import api from "./apiConfig";
import { uploadBlobToS3 } from "./awsS3";

function errorGenerator(error) {
  const info = {};

  if (error.response) {
    Object.assign(info, {
      data: error.response.data,
      error: error.response.status,
    });
  } else {
    Object.assign(info, {
      data: { message: "Something went wrong" },
      error: "Bad Network",
    });
  }

  return info;
}

const saveUser = (data) => {
  return new Promise(async function (resolve, reject) {
    try {
      const result = await api.post("/ekush/user", data);
      resolve(result);
    } catch (error) {
      const info = errorGenerator(error);
      reject(info);
    }
  });
};

const getCorpus = (data) => {
  return new Promise(async function (resolve, reject) {
    try {
      const result = await api.post("/ekush/corpus", data);
      resolve(result);
    } catch (error) {
      const info = errorGenerator(error);
      reject(info);
    }
  });
};

const saveImages = async (files, uid, textDataId) => {
  return new Promise(async function (resolve, reject) {
    try {
      // const fileUploadForm = new FormData();
      const fileUploadForm = {};

      // fileUploadForm.append("imageUrls", files);
      const fileUrls = [];
      for (let i = 0; i < files.length; i++) {
        const s3Url = await uploadBlobToS3(files[i]);
        fileUrls.push(s3Url);
      }
      fileUploadForm["uid"] = uid;
      fileUploadForm["imageUrls"] = fileUrls;
      fileUploadForm["tid"] = textDataId;

      const result = await api.post("/ekush/submit", fileUploadForm);

      resolve(result);
    } catch (error) {
      const info = errorGenerator(error);
      reject(info);
    }
  });
};

const getTarget = () => {
  return new Promise(async function (resolve, reject) {
    try {
      const result = await api.get("/ekush/target");
      resolve(result);
    } catch (error) {
      const info = errorGenerator(error);
      reject(info);
    }
  });
};

const getContributors = (pageination) => {
  return new Promise(async function (resolve, reject) {
    try {
      const apiUrl = `/ekush/contributors/${pageination ? pageination : ""}`;
      const result = await api.get(apiUrl);
      resolve(result);
    } catch (error) {
      const info = errorGenerator(error);
      reject(info);
    }
  });
};

export { saveUser, getCorpus, saveImages, getTarget, getContributors };
