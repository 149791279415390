import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import completedReducer from "./completedReducer";
import currentPageReducer from "./currentPageReducer";
import uploadingReducer from "./uploadingReducer";

import { testReducer } from "./index";
import pageTrackingReducer from "./pageTrackingReducer";
import footerReducer from "./footerReducer";

const persistConfig = {
  key: "root",
  storage,
};

const appReducer = combineReducers({
  testState: testReducer,
  pageTracking: pageTrackingReducer,
  currentPage: currentPageReducer,
  completedLevel: completedReducer,
  uploading: uploadingReducer,
  footer: footerReducer,
});

const rootReducer = (state, action) => {
  return appReducer(state, action);
};

export default persistReducer(persistConfig, rootReducer);
//export default rootReducer;
