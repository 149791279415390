const completionContent = {
  top_section1:
    "অসাধারণ, Saeef! একজন ডিজিটাল ভাষা শিল্পী হিসাবে অবদান রাখার জন্য আপনাকে অসংখ্য ধন্যবাদ। ",
  top_section2:
    "আপনার হাতের লেখা থেকে আমি এখন আরও সুন্দর করে বাংলা বুঝতে শিখবো। নতুন নতুন বাংলা শব্দ শিখতে পেরে আমি খুবই কৃতজ্ঞ।",
  bottom_section1:
    "বাংলা ভাষা নির্ভুলভাবে চিনতে পারার জন্য আমার প্রয়োজন অন্তত ৩০,০০০ বাংলা হাতের লেখা। আর্টিফিশিয়াল ইন্টেলিজেন্স রোবটদের মধ্যেও বাংলাকে সবচেয়ে মধুর ভাষা হিসেবে গড়ে তুলতে আপনার প্রিয়জনদের সাথে এই গেমটি শেয়ার করুন!",
  score: "Saeef, আপনার সর্বমোট স্কোরঃ ১৪২০ পয়েন্ট",
  bottom_section3:
    "আপনি প্রথম ২০ জনের মধ্যে একজন যিনি আমাকে বাংলা ভাষা শিখতে সবচাইতে বেশি সাহায্য করেছেন।",
  bottom_section4:
    "আপনি যত বেশি মানুষ রেফার করবেন, আপনার পয়েন্ট এবং র‍্যাঙ্কিং তত বাড়বে ও আমাকে বাংলা ভাষা শিখতে আপনার তত বেশি অবদান থাকবে। ",
};

export default completionContent;
