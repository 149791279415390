import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  dashedLineStyle: {
    border: "none",
    borderBottom: "1px dashed rgba(34, 47, 62, 0.16)",
    borderLeft: "1px dashed rgba(34, 47, 62, 0.16)",
  },
}));

const DashedLine = () => {
  const classes = useStyles();

  return <hr className={classes.dashedLineStyle} />;
};

export default DashedLine;
