import Completion from "./components/Completion";
import Landing from "./components/Landing";
import TextDisplay from "./components/TextDisplay";
import Uploading from "./components/Uploading";
import Participents from "./components/Participents/index";

const routes = {
  landing: {
    id: 1,
    data: null,
    child: <Landing />,
    completed: false,
    score: "+২৫",
  },
  textDisplay: {
    id: 2,
    data: null,
    child: <TextDisplay />,
    completed: false,
    score: "+৭৫",
  },
  uploading: {
    id: 3,
    data: { count: 0 },
    child: <Uploading />,
    completed: false,
    score: "+১৫০",
  },
  completion: {
    id: 4,
    data: null,
    child: <Completion />,
    completed: false,
    score: "+৩২৫",
  },
  participents: {
    id: 5,
    data: null,
    child: <Participents />,
    completed: false,
    score: "+৪২৫",
  },
};

export default routes;
