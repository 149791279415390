import * as React from "react";
import {
  Container,
  Grow,
  IconButton,
  ClickAwayListener,
} from "@material-ui/core";
import {
  makeStyles,
  withStyles,
  useTheme,
  useMediaQuery,
} from "@material-ui/core";
import { Button } from "@material-ui/core";
import { connect } from "react-redux";
import { currentPageAction } from "../../redux/actions/currentPageAction";
import { pageTrackingAction } from "../../redux/actions/pageTrackingAction";
import findNextPageOf from "../../utils/findNextPageOf";
import robotWaving from "../../assets/robotWaving.svg";
import robotInAwe from "../../assets/robotInAwe.svg";
import completionContent from "./content";
import RightArrow from "./../../assets/icons/RightArrow";
import LinearProgress from "@material-ui/core/LinearProgress";
import DashedLine from "./../common/DashedLine";
import Copy from "./../../assets/icons/Copy";
import Share from "./../../assets/icons/Share";
import Footer from "../Footer";
import ShareBar from "./../common/ShareBar";
import { getTarget } from "../../network/services";

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 8,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor: "r#cacaca",
  },
  bar: {
    borderRadius: 5,
    backgroundColor: "rgba(54, 43, 115, 1)",
  },
}))(LinearProgress);

const useStyles = makeStyles((theme) => ({
  completionContainer: {
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down("sm")]: {
      padding: 0,
    },
  },
  sectionContiner: {
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    backdropFilter: "blur(24px)",
    background: "rgba(255, 255, 255, 0.32)",
    borderRadius: "80px",
    [theme.breakpoints.down("sm")]: {
      borderRadius: 0,
      padding: 0,
      marginBottom: 0,
      paddingTop: 20,
    },
  },
  imageContainer: {
    display: "flex",
    padding: 10,
    justifyContent: "center",
    "& img": {
      filter: "contrast(1.2)",
      height: 226,
      width: 222,
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: 0,
      "& img": {
        height: 180,
        width: 180,
      },
    },
  },
  topSection: {
    display: "flex",
    flexDirection: "row",
    width: "80%",
    margin: "auto",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      marginTop: theme.spacing(0),
    },
  },
  topSectionContent: {
    textAlign: "left",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    width: "60%",
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0,
      width: "100%",
      textAlign: "center",
    },
    "& h2": {
      marginTop: 0,
    },
  },
  top_section1: {
    fontSize: 22,
    fontWeight: 500,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  bottomSection: {
    width: "80%",
    margin: "auto",
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      marginTop: theme.spacing(0),
    },
  },
  bottom_section1: {
    fontSize: 24,
    fontWeight: 600,
  },
  returnSectionContainerForMobile: {
    display: "flex",
    justifyContent: "center",
  },
  bottomSectionAbout: {
    width: "70%",
    margin: "auto",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  soreSection: {
    color: "rgba(54, 43, 115, 1)",
    fontSize: 22,
    fontWeight: 500,
    paddingLeft: 10,
    paddingRight: 10,
  },
  uploadButton: {
    background: "rgba(205, 38, 51, 1)",
    borderRadius: 45,
    border: "none",
    padding: theme.spacing(1.5),
    cursor: "pointer",
    width: "fit-content",
    paddingRight: theme.spacing(2.5),
    margin: theme.spacing(1),
    marginLeft: 0,
    "& .MuiButton-label": {
      marginTop: 0,
      marginLeft: 5,
    },
    "&:hover": {
      backgroundColor: "#cd2634e6",
    },
  },
  uploadButtonText: {
    textTransform: "none",
    marginTop: 0,
    fontSize: 18,
    fontWeight: 400,
    color: "#FFFFFF",
    marginRight: theme.spacing(2),
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontFamily: "Noto Sans Bengali",
  },
  linkSection: {
    borderRadius: 110,
    background: "#FFFFFF",
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    marginTop: theme.spacing(1),
    border: "1px solid rgba(36, 37, 38, 0.2)",
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
    },
  },
  linkContent: {
    color: "rgba(36, 37, 38, 1)",
    opacity: 0.6,
    [theme.breakpoints.down("sm")]: {
      marginTop: 2,
    },
  },
  progress: {
    marginTop: theme.spacing(2),
  },
  progressContent: {
    display: "flex",
    justifyContent: "space-between",
  },
  progressParagraph: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    opacity: 0.6,
  },
  lineContainer: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  linkWrapper: {
    marginTop: 0,
    [theme.breakpoints.down("sm")]: {
      fontSize: 14,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
  },
  iconButton: {
    marginLeft: theme.spacing(1),
  },
  linkTalks: {
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
      marginTop: 10,
    },
  },
  linkShareBar: {
    borderRadius: "110px 16px 48px 110px",
    background: "#FFFFFF",
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    marginTop: theme.spacing(1),
    width: "fit-content",
    boxShadow: "0px 4px 16px rgba(10, 4, 60, 0.08)",
    zIndex: 111,
    position: "absolute",
  },
  linkShareBarWrapper: {
    width: "100%",
    display: "flex",
    flexDirection: "row-reverse",
  },
  bottom_section4: {
    [theme.breakpoints.down("sm")]: {
      marginLeft: 20,
      marginRight: 20,
    },
  },
}));

function Completion({ setPageTracking, setCurrentPage, pageTrackerData }) {
  const classes = useStyles();
  const theme = useTheme();
  const match = useMediaQuery(theme.breakpoints.down("sm"));
  const [linkCopyFlag, setLinkCopyFlag] = React.useState(false);
  const [isShareActive, setShareActive] = React.useState(false);
  const [target, setTarget] = React.useState(null);
  const [achived, setAchived] = React.useState(null);

  React.useEffect(() => {
    getTarget()
      .then((result) => {
        const { data } = result;
        setTarget(data.target);
        setAchived(data.achieved);
      })
      .catch((err) => console.log(err));
  }, []);

  const copyLink = (data) => {
    navigator.clipboard.writeText(data);
    setLinkCopyFlag(true);

    setTimeout(() => {
      setLinkCopyFlag(false);
    }, 2000);
  };

  function toggleShareActive(hoverFlag) {
    setShareActive(hoverFlag);
  }

  function onClickAwayReturnSection(e) {
    toggleShareActive(false);
  }

  function localStringConv(data) {
    if (data == null) return null;
    return data.toLocaleString("bn-BD");
  }

  function calcPercent(achived, target) {
    if (achived == null || target == null) return null;

    return achived / target;
  }

  const onChangePage = () => {
    const newPageTrackerData = {
      ...pageTrackerData,
      completion: {
        ...pageTrackerData["completion"],
        completed: true,
      },
    };
    //console.log(newPageTrackerData);
    setPageTracking(newPageTrackerData);
    setCurrentPage(findNextPageOf("completion"));
  };

  function generateUrl() {
    return `ekush.ai?refName=${capitalizeFirstLetter(
      pageTrackerData.landing.data.userName
    )}`;
  }

  return (
    <Container maxWidth="md" className={classes.completionContainer}>
      <div className={classes.sectionContiner}>
        <div className={classes.topSection}>
          <div className={classes.imageContainer}>
            <Grow in={true}>
              <img src={robotWaving} alt="robothellogit" />
            </Grow>
          </div>
          <div className={classes.topSectionContent}>
            <p className={classes.top_section1}>
              {`অসাধারণ, ${capitalizeFirstLetter(
                pageTrackerData.landing.data.userName
              )}! একজন ডিজিটাল ভাষা শিল্পী হিসাবে অবদান রাখার জন্য আপনাকে অসংখ্য ধন্যবাদ।`}
            </p>
            <p>{completionContent.top_section2}</p>
          </div>
        </div>
      </div>

      <div className={classes.sectionContiner}>
        <div>
          <div className={classes.bottomSection}>
            <div className={classes.imageContainer}>
              <Grow in={true} timeout={2000}>
                <img src={robotInAwe} alt="robothellogit" />
              </Grow>
            </div>
            <div className={classes.topSectionContent}>
              <p className={classes.top_section2} style={{ fontWeight: 500 }}>
                {completionContent.bottom_section1}
              </p>
              <ClickAwayListener onClickAway={onClickAwayReturnSection}>
                <div>
                  <div className={classes.linkTalks}>
                    <p className={classes.linkContent}>আপনার রেফারেল লিঙ্ক</p>
                    {match && (
                      <div>
                        <IconButton
                          size="small"
                          className={classes.iconButton}
                          onClick={() => copyLink(generateUrl())}
                        >
                          <Copy />
                        </IconButton>
                        <IconButton
                          size="small"
                          className={classes.iconButton}
                          onClick={() => toggleShareActive(true)}
                        >
                          <Share />
                        </IconButton>
                      </div>
                    )}
                    {linkCopyFlag && (
                      <p className={classes.linkContent}>লিঙ্ক কপি হয়েছে!</p>
                    )}
                  </div>

                  <div className={classes.linkSection}>
                    <p className={classes.linkWrapper}>{generateUrl()}</p>
                    {!match && (
                      <div>
                        <IconButton
                          size="small"
                          className={classes.iconButton}
                          onClick={() => copyLink(generateUrl())}
                        >
                          <Copy />
                        </IconButton>
                        <IconButton
                          size="small"
                          className={classes.iconButton}
                          onClick={() => toggleShareActive(true)}
                        >
                          <Share />
                        </IconButton>
                      </div>
                    )}
                  </div>

                  {isShareActive ? (
                    <div className={classes.linkShareBarWrapper}>
                      <div className={classes.linkShareBar}>
                        <ShareBar urlString={generateUrl()} />
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </ClickAwayListener>
              <div className={classes.progress}>
                <div className={classes.progressContent}>
                  <p
                    className={classes.progressParagraph}
                    style={{ textAlign: "left" }}
                  >
                    লক্ষ্য অর্জিত
                  </p>
                  <p
                    className={classes.progressParagraph}
                    style={{ textAlign: "right" }}
                  >
                    {localStringConv(achived)} / {localStringConv(target)} হাতের
                    লেখা
                  </p>
                </div>
                <BorderLinearProgress
                  variant={"determinate"}
                  value={calcPercent(achived, target)}
                />
                <p className={classes.progressParagraph}>
                  আরও {localStringConv(target - achived)} হাতের লেখা প্রয়োজন।
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className={classes.lineContainer}>
          <DashedLine />
        </div>
        <div className={classes.bottomSectionAbout}>
          <p className={classes.soreSection}>
            {capitalizeFirstLetter(pageTrackerData.landing.data.userName)},
            <br /> আপনার সর্বমোট স্কোরঃ ১৮৯৯ পয়েন্ট
          </p>

          <p className={classes.bottom_section4}>
            {" "}
            {completionContent.bottom_section4}
          </p>
          <Button className={classes.uploadButton} onClick={onChangePage}>
            <p className={classes.uploadButtonText}>সকল সহযোগীর তালিকা দেখুন</p>
            <RightArrow />
          </Button>
        </div>
      </div>
      <Footer />
    </Container>
  );
}

const mapStateToProps = (state) => {
  return { pageTrackerData: state.pageTracking.pageTracker };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setPageTracking: (data) => dispatch(pageTrackingAction(data)),
    setCurrentPage: (data) => dispatch(currentPageAction(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Completion);
