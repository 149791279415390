const initState = { showParticipents: false };

const footerReducer = (state = initState, action) => {
  switch (action.type) {
    case "TOGGLE_PARTICIPENTS_VIEW":
      return {
        ...state,
        showParticipents: !state.showParticipents,
      };

    default:
      return state;
  }
};

export default footerReducer;
