import * as React from "react";
import { Button, Container } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";

import { connect } from "react-redux";
import { currentPageAction } from "../../redux/actions/currentPageAction";
import { pageTrackingAction } from "../../redux/actions/pageTrackingAction";
import findNextPageOf from "../../utils/findNextPageOf";

//import robotReading from "../../assets/robotReading.svg";
import robotWithMobile from "../../assets/robotWithMobile.svg";
import Ellipse from "../../assets/Ellipse.svg";
import uploadContent from "./content";
//import LinearProgress from "@material-ui/core/LinearProgress";
import RightArrow from "./../../assets/icons/RightArrow";
import { completedLevelAction } from "../../redux/actions/compeltedLevelAction";
import { uploadingAction } from "./../../redux/actions/uploadingAction";
import Footer from "../Footer";

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

// const BorderLinearProgress = withStyles((theme) => ({
//   root: {
//     height: 10,
//     borderRadius: 5,
//   },
//   colorPrimary: {
//     backgroundColor:
//       theme.palette.grey[theme.palette.type === "light" ? 200 : 700],
//   },
//   bar: {
//     borderRadius: 5,
//     backgroundColor: "rgba(205, 38, 51, 1)",
//   },
// }))(LinearProgress);

const useStyles = makeStyles((theme) => ({
  uploadingContainer: {
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down("sm")]: {
      padding: 0,
    },
  },
  topSection: {
    textAlign: "center",
    background: "rgba(255, 255, 255, 0.32)",
    borderRadius: "80px",
    paddingBottom: theme.spacing(2),
    backdropFilter: "blur(24px)",
    margin: theme.spacing(2),
    justifyContent: "center",
  },
  header: {
    color: "#CD2633",
    [theme.breakpoints.down("sm")]: {
      fontSize: 26,
      marginTop: theme.spacing(2),
    },
  },

  subtitle: {
    [theme.breakpoints.down("sm")]: {
      fontSize: 20,
    },
  },
  top_section1: {
    width: "75%",
    fontSize: 22,
    fontWeight: 500,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  top_section2: {
    width: "75%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  imageContainer: {
    display: "flex",
    padding: 10,
    justifyContent: "center",
    "& img": {
      borderRadius: "50%",
      filter: "contrast(1.2)",
      height: 120,
      width: 120,
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: 0,
      "& img": {
        height: 120,
        width: 120,
      },
    },
  },
  imageContainerAfter: {
    display: "flex",
    padding: 10,
    justifyContent: "center",
    alignItems: "center",
    "& img": {
      filter: "contrast(1.2)",
      height: 180,
      width: 180,
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: 0,
      "& img": {
        height: 120,
        width: 120,
      },
    },
  },
  topSectionContent: {
    textAlign: "left",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    marginLeft: theme.spacing(10),
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0,
    },
    "& h2": {
      marginTop: 0,
    },
  },
  returnSection: {
    padding: "8px 15px 8px 15px",
    background: "white",
    borderRadius: 110,
    fontWeight: 600,
    width: "fit-content",
    cursor: "none",
    textAlign: "center",
    border: "1px solid #95a5a78a",
    [theme.breakpoints.down("sm")]: {
      fontSize: 15,
    },
    //color: "rgba(54, 43, 115, 1)",
  },
  ekush_placeholder: {
    marginLeft: 3,
    marginRight: 3,
    fontWeight: 400,
    fontSize: 20,
    [theme.breakpoints.down("sm")]: {
      fontSize: 17,
      fontWeight: 600,
    },
  },
  bottomSection: {
    background: "rgba(255, 255, 255, 0.32)",
    borderRadius: "80px",
    padding: theme.spacing(2),
    backdropFilter: "blur(24px)",
    margin: theme.spacing(2),
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      borderRadius: 16,
      flexDirection: "column-reverse",
      marginTop: 0,
      paddingTop: 0,
    },
  },
  bottom_section1: {
    fontSize: 24,
    fontWeight: 600,
  },
  bottom_section2: {
    fontSize: 20,
    fontWeight: 400,

    marginTop: theme.spacing(1),
    [theme.breakpoints.down("sm")]: {
      fontSize: 18,
      lineHeight: 1.4,
      marginBottom: 15,
    },
  },
  inputField: {
    width: "50%",
    margin: "auto",
    marginTop: theme.spacing(2),
  },
  section_2: {
    fontWeight: 500,
  },
  returnSectionContainerForMobile: {
    display: "flex",
    justifyContent: "center",
  },
  bottomSectionWrapper: {
    textAlign: "left",
    marginLeft: theme.spacing(5),
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0,
      textAlign: "center",
    },
  },
  progressContainer: {
    width: "40%",
    margin: "auto",
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  topSectionAfterWrapper: {
    marginBottom: theme.spacing(2),
    backdropFilter: "blur(24px)",
    borderRadius: "80px",
    background: "rgba(255, 255, 255, 0.32)",
    padding: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      borderRadius: 0,
    },
  },
  topSectionAfter: {
    display: "flex",
    flexDirection: "row",
    width: "80%",
    justifyContent: "center",
    margin: "auto",

    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      marginTop: theme.spacing(0),
    },
  },
  topSectionContentAfter: {
    textAlign: "left",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    marginLeft: theme.spacing(10),
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0,
      textAlign: "center",
    },
    "& h2": {
      marginTop: 0,
    },
  },
  uploadButton: {
    background: "rgba(205, 38, 51, 1)",
    borderRadius: 45,
    border: "none",
    padding: theme.spacing(1.5),
    cursor: "pointer",
    width: "fit-content",
    paddingRight: theme.spacing(2.5),
    margin: theme.spacing(1),
    marginLeft: 0,
    "& .MuiButton-label": {
      marginTop: 0,
      marginLeft: 5,
    },

    "&:hover": {
      backgroundColor: "#cd2634e6",
    },
  },
  uploadButtonText: {
    textTransform: "none",
    marginTop: 0,
    fontSize: 18,
    fontWeight: 400,
    color: "#FFFFFF",
    marginRight: theme.spacing(4),
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontFamily: "Noto Sans Bengali",
    marginLeft: 12,
  },
  bottomSectionContainer: {
    display: "flex",
    flexDirection: "column",
  },
  bottomSectionFact: {
    padding: theme.spacing(2),
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      borderRadius: 16,
      flexDirection: "column-reverse",
      marginTop: 0,
      paddingTop: 0,
    },
  },
  bottomSectionWrapperAfter: {
    margin: 16,
  },
}));

function generateRandomFact() {
  const high = uploadContent.fact_list.length;
  const index = Math.floor(Math.random() * high);

  return uploadContent.fact_list[index];
}

// function During({ onComplete }) {
//   const [progress, setProgress] = React.useState(0);

//   React.useEffect(() => {
//     const timer = setInterval(() => {
//       const diff = Math.random() * 40;
//       const newProgress = Math.min(progress + diff, 100);
//       setProgress(newProgress);

//       if (newProgress === 100) {
//         onComplete();
//         clearInterval(timer);
//       }
//     }, 500);

//     return () => {
//       clearInterval(timer);
//     };
//   }, [onComplete, progress]);

//   const classes = useStyles();
//   return (
//     <>
//       <div className={classes.topSection}>
//         <div className={classes.imageContainer}>
//           <img src={robotReading} alt="robothellogit" />
//         </div>
//         <p className={classes.bottom_section1}>ছবি আপলোড হচ্ছে</p>
//         <div className={classes.progressContainer}>
//           <BorderLinearProgress variant="determinate" value={progress} />
//           <p className={classes.bottom_section2}>{`${parseInt(
//             progress
//           ).toLocaleString("bn-BD")} %`}</p>
//         </div>
//       </div>
//     </>
//   );
// }

function AfterUpload({ onChangePage, userName }) {
  const [fact, setFact] = React.useState(null);
  React.useEffect(() => {
    if (!fact) {
      setFact(generateRandomFact());
    }
  }, [fact]);

  const classes = useStyles();
  return (
    <>
      <div className={classes.topSectionAfterWrapper}>
        <div className={classes.topSectionAfter}>
          <div className={classes.imageContainerAfter}>
            <img src={robotWithMobile} alt="robothellogit" />
          </div>
          <div className={classes.topSectionContentAfter}>
            <p className={classes.top_section1}>
              {`অসাধারণ, ${capitalizeFirstLetter(
                userName
              )}! আপনার হাতের লেখা জমা দেওয়ার জন্য অসংখ্য ধন্যবাদ।`}
            </p>
            <p className={classes.top_section2}>
              {uploadContent.after_upload_bottom2}
            </p>
          </div>
        </div>
        <div className={classes.bottomSectionWrapperAfter}>
          <p className={classes.bottom_section2}>
            {uploadContent.after_upload_bottom3}
          </p>
          <Button className={classes.uploadButton} onClick={onChangePage}>
            <p className={classes.uploadButtonText}>পরবর্তী ধাপ</p>
            <RightArrow />
          </Button>
        </div>
      </div>
      <div className={classes.bottomSection}>
        <div className={classes.bottomSectionFact}>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <img src={Ellipse} alt="Ellipse" />
          </div>
          <div className={classes.bottomSectionWrapper}>
            <p className={classes.bottom_section1}>
              {uploadContent.bottom_section1}
            </p>
            <p className={classes.bottom_section2}>{fact}</p>
          </div>
        </div>
      </div>
    </>
  );
}

function Uploading({
  setPageTracking,
  setCurrentPage,
  pageTrackerData,
  setCompletedLevel,
  completedLevel,
  //uploading,
  setUploadingState,
}) {
  const classes = useStyles();

  React.useEffect(() => {
    // setCompletedLevel();

    if (completedLevel >= 5) setCurrentPage(findNextPageOf("uploading"));
  }, [completedLevel, setCompletedLevel, setCurrentPage]);

  const onChangePage = () => {
    const { count } = pageTrackerData.uploading.data;
    const newPageTrackerData = {
      ...pageTrackerData,
      uploading: {
        ...pageTrackerData["uploading"],
        data: { count: count + 1 },
        completed: true,
      },
    };

    setUploadingState(false);
    setPageTracking(newPageTrackerData);

    setCurrentPage("textDisplay");
  };

  // function handleComplete() {
  //   setCompletedLevel();

  //   setUploadingState(true);
  // }

  return (
    <Container maxWidth="md" className={classes.uploadingContainer}>
      <AfterUpload
        onChangePage={onChangePage}
        userName={pageTrackerData.landing.data.userName}
        setCompletedLevel={setCompletedLevel}
      />
      <Footer />
    </Container>
  );
}

const mapStateToProps = (state) => {
  return {
    pageTrackerData: state.pageTracking.pageTracker,
    completedLevel: state.completedLevel.completed,
    uploading: state.uploading.uploading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setPageTracking: (data) => dispatch(pageTrackingAction(data)),
    setCurrentPage: (data) => dispatch(currentPageAction(data)),
    setCompletedLevel: () => dispatch(completedLevelAction()),
    setUploadingState: (data) => dispatch(uploadingAction(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Uploading);
