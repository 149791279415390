export const completedLevelAction = () => {
  return (dispatch) => {
    dispatch({ type: "INC_COMPLETION" });
  };
};

export const resetLevelAction = () => {
  return (dispatch) => {
    dispatch({ type: "RESET_COMPLETION" });
  };
};
