import { makeStyles } from "@material-ui/core";
import heart from "../../assets/heart.svg";
import { connect } from "react-redux";
import { toggleParticipentsView } from "../../redux/actions/footerAction";
const useStyles = makeStyles((theme) => ({
  footerContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    paddingBottom: 20,
    paddingTop: 15,
    margin: 20,
    flex: 1,

    [theme.breakpoints.down("sm")]: {
      // borderTop: "1px solid #00000026",
      width: "100%",
      margin: 0,
    },

    "& p": {
      marginTop: 0,
      fontSize: 12,
      fontWeight: 500,
    },

    "& img": {
      width: 14,
      height: 14,
      marginLeft: 5,
      marginBottom: 2,
    },
  },
}));
function Footer({ showParticipentsView, toggleParticipentsView }) {
  const classes = useStyles();
  return (
    <div className={classes.footerContainer}>
      <p>
        তৈরিতে ইন্টেলিজেন্ট মেশিন্স ও{" "}
        <span onClick={toggleParticipentsView} style={{ cursor: "pointer" }}>
          আপনি{" "}
        </span>
      </p>
      <img src={heart} alt="heart" />
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    showParticipentsView: state.footer.showParticipents,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    toggleParticipentsView: () => dispatch(toggleParticipentsView()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Footer);
