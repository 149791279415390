import routes from "../_routing";

const findNextPageOf = (currentPage) => {
  const newPageId = routes[currentPage].id + 1;

  var newPage = Object.keys(routes).find((key) => routes[key].id === newPageId);
  if (newPage === undefined) newPage = "landing";
  return newPage;
};

export default findNextPageOf;
